export default {
    fields: {
        tree_count: {
            label: '# of Trees',
            type: 'number',
            getDescription: () => 'If you edit this field you must save and reopen this form before changes will be reflected below'
        },
        cluster_count: {
            label: '# of Clusters per Tree',
            type: 'number',
        },
        flower_count: {
            label: '# of Fruitlets per Cluster',
            type: 'number',
        },
        static: {
            label: 'Flower Clusters Counted per Tree',
            type: 'static'
        },
        clusters_counted_per_tree: {
            label: 'Avg. Flower Clusters Counted per Tree',
            type: 'number',
        },
        target_fruit_per_tree: {
            label: 'Target Fruit per Tree',
            type: 'number',
        },
        potential_fruit_per_tree: {
            label: 'Potential Fruit per Tree',
            type: 'number',
        },
    }
}
