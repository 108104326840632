<template>
    <div class="data-form-input"
         :class="field.class"
         v-if="field.type !== 'hidden'">

        <b-form-group :horizontal="horizontal"
                      v-if="field && fieldShown"
                      :disabled="fieldDisabled"
                      :label-cols="horizontal ? 6 : null"
                      :invalid-feedback="invalidFeedback(field)"
                      :valid-feedback="validFeedback(field)">

            <slot name="label" v-if="field.type !== 'check'">
                <span class="required-marker" v-if="required && !fieldDisabled">
                    *
                </span>
                {{ label }}
                <helper v-if="field.helpTag" :tag="field.helpTag" />
            </slot>

            <!-- radio group -->
            <b-form-radio-group v-if="field.type === 'radios'"
                                :class="`t-${fieldName}`"
                                v-model="localValue"
                                :options="fieldOptions"
                                @input="fieldOnChange"
                                @blur="blur"
                                :state="state(field)" />

            <!-- checkbox -->
            <b-form-checkbox v-else-if="field.type === 'check'"
                             :class="`t-${fieldName}`"
                             v-model="localValue"
                             @input="fieldOnChange"
                             @blur="blur"
                             :state="state(field)">
                {{ label }}
            </b-form-checkbox>

            <!-- checkbox group -->
            <b-form-checkbox-group v-else-if="field.type === 'checks'"
                                   :class="`t-${fieldName}`"
                                   v-model="localValue"
                                   :options="fieldOptions"
                                   @input="fieldOnChange"
                                   @blur="blur"
                                   :state="state(field)" />

            <!-- select -->
            <b-form-select v-else-if="field.type === 'select'"
                           :class="`t-${fieldName}`"
                           :autocomplete="field.autocomplete"
                           v-model="localValue"
                           :options="fieldOptions"
                           @input="fieldOnChange"
                           @blur="blur"
                           :state="state(field)" />

            <!-- multiselect -->
            <template v-else-if="field.type === 'multiselect'">
                <data-form-input-multiselect :class="`t-${fieldName}`"
                                             :value="value"
                                             v-model="localValue"
                                             :$v="$v"
                                             :field-model="fieldModel"
                                             :field-name="fieldName"
                                             :model-instance="modelInstance"
                                             :state="state(field)"
                                             :form="form" />
            </template>

            <!-- typeahead -->
            <gorges-vue-typeahead v-else-if="field.type === 'typeahead'"
                                  :class="`t-${fieldName}`"
                                  v-model="localValue"
                                  :data="fieldOptions"
                                  @input="fieldOnChange"
                                  @blur="blur"
                                  :state="state(field)"
                                  :disabled="field.disabled" />

            <!-- textarea -->
            <b-form-textarea v-else-if="field.type === 'textarea'"
                             :class="`t-${fieldName}`"
                             :autocomplete="field.autocomplete"
                             :placeholder="field.placeholder"
                             v-model="localValue"
                             @input="fieldOnChange"
                             @blur="blur"
                             :debounce="fieldDebounce"
                             rows="3"
                             :state="state(field)"
                             :disabled="field.disabled" />

            <!-- rich text -->
            <vue-trix v-else-if="field.type === 'rich'"
                      :class="`t-${fieldName}`"
                      :autocomplete="field.autocomplete"
                      :placeholder="field.placeholder"
                      v-model="localValue"
                      @input="fieldOnChange"
                      @blur="blur"
                      :debounce="fieldDebounce"
                      rows="3"
                      :state="state(field)"
                      :disabled="field.disabled" />

            <!-- number -->
            <b-form-input v-else-if="field.type === 'number'"
                          :class="`t-${fieldName}`"
                          :type="field.type"
                          :autocomplete="field.autocomplete"
                          :placeholder="field.placeholder"
                          :step="field.step"
                          :min="field.minValue"
                          v-model="localValue"
                          @input="fieldOnChange"
                          @blur="blur"
                          :debounce="fieldDebounce"
                          :state="state(field)"
                          :disabled="field.disabled" />

            <!-- password -->
            <template v-else-if="field.type === 'password'">
                <b-form-input :class="`t-${fieldName}`"
                              :type="showPassword ? 'text' : 'password'"
                              :autocomplete="field.autocomplete"
                              :placeholder="field.placeholder"
                              v-model="localValue"
                              @input="fieldOnChange"
                              @blur="blur"
                              :debounce="fieldDebounce"
                              :state="state(field)"
                              :disabled="field.disabled" />
                <b-form-checkbox class="float-right mb-0"
                                 v-model="showPassword">
                    Show Password
                </b-form-checkbox>
            </template>

            <!-- datetime -->

            <template v-else-if="field.type === 'datetime'">
                <datetime-picker
                    v-model="localValue"
                    @input="fieldOnChange"
                    @blur="blur"
                    :state="state(field)">
                </datetime-picker>
            </template>

            <template v-else-if="field.type === 'static'">
            </template>

            <template v-else-if="field.type === 'button'">
                <b-btn v-if="!field.state || field.state(modelInstance)" class="d-block" :disabled="field.disabled"
                       @click="buttonClick(field)">{{ field.buttonLabel }}
                </b-btn>
                <b-btn v-else class="d-block" disabled>{{ field.disabledButtonLabel || field.buttonLabel }}</b-btn>
            </template>

            <weather-station-map v-else-if="field.type === 'weather_station_map'"
                                 :class="`t-${fieldName}`"
                                 v-model="localValue"
                                 @input="fieldOnChange"
                                 @blur="blur"
                                 :state="state(field)" />

            <!-- other input type - text, date, tel, etc. -->
            <b-form-input v-else-if="isRegularInputType"
                          :class="`t-${fieldName}`"
                          :type="field.type"
                          :autocomplete="field.autocomplete"
                          :placeholder="field.placeholder"
                          v-model="localValue"
                          @input="fieldOnChange"
                          @blur="blur"
                          :disabled="field.disabled"
                          :debounce="fieldDebounce"
                          :state="state(field)" />

            <span v-else>
                <b-alert show variant="danger">
                    Unsupported Input Type: {{ field.type }}
                </b-alert>
            </span>
            <slot name="description" v-if="fieldDescription">
                <small class="form-text text-muted" v-html="fieldDescription"></small>
            </slot>
            <template v-if="showCollapse">
                <div>
                    <small v-if=showCollapse.text class="form-text text-muted d-inline"
                           v-html="showCollapse.text"></small>
                    <small class="collapse-toggle d-inline" @click="toggleCollapse">{{ showCollapse.label }}</small>
                </div>
            </template>

        </b-form-group>

    </div>
</template>

<script>
/**
 * Generates a <b-form-group> with input type, translated label, validation state
 * and invalid/valid feedback based on a field model such as Participant.
 *
 * NB: text-based inputs have the "debounce" attribute added (courtesy of bootstrap-vue),
 * to make async validation much simpler (see e.g. email field in models/Participant.js).
 *
 * 'Valid' feedback is used to display warnings or other information that should not prevent form submission.
 */
import Vue from 'vue'
import * as _ from 'lodash'

import {validationMixin} from 'vuelidate'
import VueTrix from 'vue-trix'
import WeatherStationMap from "@/components/WeatherStationMap.vue";

export default Vue.extend({
    name: 'DataFormInput',
    components: {WeatherStationMap, VueTrix},
    mixins: [validationMixin],
    props: {
        value: {
            required: true
        },
        $v: {type: Object, required: true},
        fieldModel: Object,
        fieldName: String,
        modelInstance: Object,
        horizontal: Boolean,
        form: Object,
    },
    data() {
        return {
            debounceInterval: 500, // milliseconds
            showPassword: false,
            checksSelected: [], // for checkbox group
        }
    },
    computed: {
        localValue: {
            get() {
                if (this.value && this.field.type === 'date') {
                    //Bootstrap vue input doesn't like the full timestamp
                    return this.value.substr(0, 10)
                } else if (this.value && this.field.type === 'checks' && !Array.isArray(this.value)) {
                    //Check box groups must be an array in order to select multiple items
                    return [this.value]
                }
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        field() {
            return this.fieldModel.fields[this.fieldName]
        },
        fieldOptions() {
            return this.field.options
        },
        fieldDescription() {
            if (this.field.getDescription) {
                return this.field.getDescription(this.form, this.$v.form, this.fieldModel)
            }
            return null
        },
        showCollapse() {
            if (this.field.getShowCollapse) {
                return this.field.getShowCollapse(this.form, this.$v.form, this.fieldModel)
            }
            return null
        },
        fieldOnChange() {
            if (this.field.onChange) {
                return () => this.field.onChange(this.form, this.$v.form, this.fieldModel)
            } else {
                return () => {
                }
            }
        },
        fieldDebounce() {
            // if there is an onChange handler present, turn off debouncing -
            // otherwise the old model value will be passed to the handler
            if (this.field.onChange) {
                return 0
            } else {
                return this.debounceInterval
            }
        },
        required() {
            return !!this.field.validations && this.field.validations.required
        },
        label() {
            if (this.field.getLabel) {
                return this.field.getLabel(this.form, this.$v.form, this.fieldModel)
            }
            return this.field.label
        },
        isRegularInputType() {
            return ['text', 'number', 'date', 'time', 'tel', 'email'].indexOf(this.field.type) !== -1
        },
        fieldShown() {
            if (this.field.showIf) {
                return this.field.showIf(this.form, this.$v.form, this.fieldModel)
            }
            return true
        },
        fieldDisabled() {
            if (this.field.disableIf) {
                if (this.field.disableIf(this.form, this.$v.form, this.fieldModel)) {
                    this.$emit('input', null)
                }
                return this.field.disableIf(this.form, this.$v.form, this.fieldModel)
            }

            return false
        },
    },
    methods: {
        toggleCollapse() {
            this.$root.$emit('bv::toggle::collapse', this.showCollapse.id)
        },
        state(field) {
            const f = this.$v.form[this.fieldName]

            if (f) {
                return f.$dirty ? !f.$error : null
            } else {
                return null
            }
        },
        invalidFeedback(field) {
            let fb = ''

            const defaultFeedback = {
                // required: 'This field is required',
            }

            _.each(_.keys(field.validations), (v) => {
                if (!fb && !this.$v.form[this.fieldName][v]) {
                    if (field.invalidFeedback && field.invalidFeedback[v]) {
                        fb = field.invalidFeedback[v]
                    } else if (defaultFeedback[v]) {
                        fb = defaultFeedback[v]
                    }
                }
            })
            return fb
        },
        validFeedback(field) {
            const warnings = this.$parent.warnings // parent = DataForm component
            return (warnings ? warnings[this.fieldName] : null)
        },
        blur() {
            this.$v.form[this.fieldName].$touch()
        },
        populateOptions() {
            if (this.field.loadOptions) {
                this.field.loadOptions(this.modelInstance, this.$v.form, this.fieldModel)
                    .then((options) => {
                        this.options = options
                    })
            } else if (this.field.options) {
                this.options = _.map(_.keys(this.field.options), (key) => {
                    const label = this.field.options[key]
                    return {value: key, text: label}
                })
            }
            if (this.field.nullOptionLabel) {
                const label = this.field.nullOptionLabel
                this.options.splice(0, 0, {value: null, text: label})
            }
        },
        buttonClick(field) {
            const value = this.field.valueOnClick(this.modelInstance)
            // eslint-disable-next-line vue/no-mutating-props
            this.modelInstance[this.fieldName] = value
            this.$emit('input', value)
            if (field.disabledButtonLabel) field.buttonLabel = field.disabledButtonLabel
            if (field.state) field.disabled = field.state(this.modelInstance)
        },
    },
    created() {
        if (!this.field) {
            console.error(`Can't create FormInput, missing field: ${this.fieldName}`)
            return
        }

        this.populateOptions()
    },
})
</script>

<style lang="scss">
.data-form-input {
    margin-top: 10px;

    .required-marker {
        font-weight: bold;
        font-size: 1.5em;
        color: red;
    }

    label {
        text-transform: none;
        font-size: 16px;
    }

    .collapse-toggle {
        color: #C83965;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
            color: #267c60;
        }
    }
}
</style>
