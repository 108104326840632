<template>
    <div class="location-list pb-5">
        <about-modal topic="location"/>
        <heading title="Locations"/>

<!--        <spinner-until :condition="!loading">-->
            <data-table-local-sort ref="table"
                                   :items="locations"
                                   :fields="fields"
                                   :filter="filters"
                                   v-model="filters"
                                   :show-filters="true">
                <template v-slot:customField="slotProps">
                    <div v-if="slotProps.row.field.key === 'actions'">
                        <router-link class="btn btn-info"
                                     :to="{name: 'locationDetails', params: {location_id: slotProps.row.item.id}}">
                            <b-icon-eye/>
                        </router-link>
                    </div>
                </template>
            </data-table-local-sort>
<!--        </spinner-until>-->

        <location-edit-modal>
            <b-icon icon="plus"/>
            Add A Location
        </location-edit-modal>
    </div>
</template>

<script>
import * as _ from 'lodash'
import DataTableLocalSort from "@/components/DataTableLocalSort.vue"
import Heading from "@/components/Heading.vue"
import AboutModal from "@/components/AboutModal.vue"
import LocationEditModal from "@/components/LocationEditModal.vue"

export default {
    name: "LocationList",
    components: {LocationEditModal, AboutModal, Heading, DataTableLocalSort},
    data() {
        return {
            loading: false,
            fields: [
                {key: 'year', sortable: true, label: 'Year'},
                {key: 'farm', sortable: true, label: 'Farm'},
                {key: 'block', sortable: true, label: 'Block'},
                {key: 'actions', sortable: false, label: 'Actions'}
            ],
            filters: {
                year: {field: 'year', type: 'string', value: null, label: 'Year'},
                farm: {field: 'farm', type: 'string', value: null, label: 'Farm'},
                block: {field: 'block', type: 'string', value: null, label: 'Block'},
            },
            locations: []
        }
    },
    async mounted() {
        this.loading = true
        this.locations = await this.$api.getLocations()
        this.loading = false
        this.$refs.table.context.sortDesc = true // default to reverse sort by year
    },
}
</script>

<style scoped>

</style>