<template>
    <div class="breadcrumb">
        <b-icon-arrow-left-square-fill variant="primary"/> &nbsp;&nbsp;
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Breadcrumb",
    props: {},
    computed: {},
    data() {
        return {}
    }
}
</script>

<style lang="scss">

.breadcrumb {
    float:left;
    margin-left:10px;
}

</style>