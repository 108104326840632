<template>
    <div class="location-details">
        <breadcrumb>
            <router-link :to="{name: 'locations'}">
                Locations
            </router-link>
        </breadcrumb>
        <about-modal topic="location_details"/>
        <heading :title="title"/>

        <spinner-until :condition="loaded">
            <b-row>
                <b-col class="pr-2 col-12 col-lg-4">
                    <h3 class="text-center">Run Models</h3>
                    <router-link class="btn btn-primary d-block m-2 btn-icon"
                                 :to="{name: 'sprayRecords', params: {location_id: location_id}}">
                        <b-icon-eyedropper/>
                        Spray Records
                    </router-link>
                    <router-link class="btn btn-primary d-block m-2 btn-icon"
                                 :to="{name: 'fruitGrowthRateDetails', params: {location_id: location_id}}">
                        <b-icon-basket/>
                        Fruit Growth Rate Model
                    </router-link>
                    <router-link class="btn btn-primary d-block m-2 btn-icon"
                                 :to="{name: 'irrigationDetails', params: {location_id: location_id}}">
                        <b-icon-droplet-fill/>
                        Irrigation Model
                    </router-link>
                    <router-link class="btn btn-primary d-block m-2 btn-icon"
                                 :to="{name: 'irrigationRecords', params: {location_id: location_id}}">
                        <b-icon-bar-chart-fill/>
                        Irrigation Records
                    </router-link>
                    <router-link class="btn btn-primary d-block m-2 btn-icon"
                                 :to="{name: 'carbohydrateThinningDetails', params: {location_id: location_id}}">
                        <b-icon-cloud-sun-fill/>
                        Carbohydrate Thinning Model
                    </router-link>
                </b-col>

                <b-col>
                    <b-row class="location__actions mb-3">
                        <b-col>
                            <b-col class="text-center">
                                <b-button class="btn btn-danger me-2" @click="deleteLocation">
                                    <b-icon-dash-circle/>
                                    Delete
                                </b-button>
                                <location-edit-modal :model-instance="clone" class="me-2 d-inline-block">
                                    <b-icon-files/>
                                    Clone
                                </location-edit-modal>
                                <location-edit-modal :model-instance="location" class="d-inline-block">
                                    <b-icon-pencil/>
                                    Edit
                                </location-edit-modal>
                            </b-col>
                        </b-col>
                    </b-row>
                    <view-table
                        :data="location"
                        :fields="fields"
                        :model="model">
                        <template v-slot:green_tip_date>
                            {{ formatDate(location.green_tip_date) }}
                        </template>
                        <template v-slot:bloom_date>
                            {{ formatDate(location.bloom_date) }}
                        </template>
                        <template v-slot:pct_spurs_flowering>
                            {{ model.fields.pct_spurs_flowering.options[location.pct_spurs_flowering] }}
                        </template>
                        <template v-slot:orchard_age>
                            {{ model.fields.orchard_age.options[location.orchard_age] }}
                        </template>
                        <template v-slot:spacing_in_row>
                            {{ location.spacing_in_row }} ft
                        </template>
                        <template v-slot:spacing_between_rows>
                            {{ location.spacing_between_rows }} ft
                        </template>
                        <template v-slot:tree_width>
                            {{ location.tree_width }} ft
                        </template>
                        <template v-slot:tree_height>
                            {{ location.tree_height }} ft
                        </template>
                        <template v-slot:emitter_spacing_in_row>
                            {{ location.emitter_spacing_in_row }} ft
                        </template>
                        <template v-slot:emitter_spacing_between_rows>
                            {{ location.emitter_spacing_between_rows }} ft
                        </template>
                        <template v-slot:emitter_flow>
                            {{ location.emitter_flow }} gal/hr
                        </template>
                        <template v-slot:application_rate>
                            {{ location.application_rate }} GPH/acre
                        </template>
                        <template v-slot:water_holding_capacity>
                            {{ location.water_holding_capacity }} gal/acre
                        </template>
                        <template v-slot:pct_water_holding_capacity>
                            {{ location.water_holding_capacity * .2 }} gal/acre
                        </template>
                    </view-table>
                    <b-row class="location__actions">
                        <b-col class="text-center">
                            <b-button class="btn btn-danger me-2" @click="deleteLocation">
                                <b-icon-dash-circle/>
                                Delete
                            </b-button>
                            <location-edit-modal :model-instance="clone" class="me-2 d-inline-block">
                                <b-icon-files/>
                                Clone
                            </location-edit-modal>
                            <location-edit-modal :model-instance="location" class="d-inline-block">
                                <b-icon-pencil/>
                                Edit
                            </location-edit-modal>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <em>Location ID: {{ locationId }}</em>
        </spinner-until>
    </div>
</template>

<script>
import ViewTable from "@/components/ViewTable.vue"
import LocationFields from "@/models/locationFields"
import SpinnerUntil from "@/components/SpinnerUntil.vue"
import Heading from "@/components/Heading.vue"
import AboutModal from "@/components/AboutModal.vue"
import LocationEditModal from "@/components/LocationEditModal.vue"
import _ from "lodash"
import Utils from "@/providers/Utils";

export default {
    name: "LocationDetails",
    components: {LocationEditModal, AboutModal, Heading, SpinnerUntil, ViewTable},
    props: {
        location_id: String,
    },
    data() {
        return {
            location: null,
            fields: [
                'year',
                'farm',
                'block',
                'variety',
                'weather_station_title_dummy',
                'green_tip_date',
                'bloom_date',
                'pct_spurs_flowering',
                'orchard_age',
                'spacing_in_row',
                'spacing_between_rows',
                'trees_per_acre',
                'tree_width',
                'tree_height',
                'tree_row_volume',
                'emitter_spacing_in_row',
                'emitter_spacing_between_rows',
                'emitters_per_acre',
                'emitter_flow',
                'application_rate',
                'water_holding_capacity',
                'pct_water_holding_capacity'
            ],
            model: LocationFields,
        }
    },

    async mounted() {
        this.location = await this.$api.getLocation(this.location_id)

        if (this.hasInvalidWeatherStationId()) {
            await this.$bvModal.msgBoxOk('The weather station selected for this Location is no longer valid, due to changes to the database - please edit the Location and re-select the appropriate station.', {
                title: 'Invalid Weather Station',
            })
        }
    },
    // necessary when redirecting to newly cloned location
    async beforeRouteUpdate(to, from, next) {
        this.location = await this.$api.getLocation(to.params.location_id)
        next()
    },
    computed: {
        loaded() {
            return !!this.location?.id
        },
        title() {
            const farm = this.location?.farm ? `${this.location.farm} - ` : ''
            const block = this.location?.block ? `${this.location.block} - ` : ''
            const variety = this.location?.variety ? `${this.location.variety} ` : ''
            const year = this.location?.year ? `(${this.location.year}) ` : ''

            return farm + block + variety + year
        },
        locationId() {
            return this.location?.id ? `${this.location.id}` : ''
        },
        clone() {
            let clone = {}

            // only clone if location is loaded
            if (this.loaded) {
                clone = _.clone(this.location)

                // delete id so new record is created on LocationEditModal submit
                if ('id' in clone) delete clone.id

                clone.year = _.toNumber(this.location.year) + 1
                const age = this.location.orchard_age

                if (age !== 'mature' && _.toNumber(age) <= 3) {
                    clone.orchard_age = age + 1
                } else {
                    clone.orchard_age = 'mature'
                }
            }
            return clone
        }
    },
    methods: {
        deleteLocation() {
            this.$bvModal.msgBoxConfirm('Permanently delete this Location?', {
                title: 'Confirm deletion',
                okTitle: 'Delete'
            }).then(async (value) => {
                if (value) {
                    await this.$api.deleteLocation(this.location)
                    this.$toasted.info('Location deleted')
                    await this.$router.replace({name: 'locations'})
                }
            })
        },
        hasInvalidWeatherStationId() {
            // NB: these are the unique old station IDs in use at the moment - there are no doubt other invalid IDs...
            // (station ID structure has changed to include network within the ID)
            const oldIds = [
                '289',
                '3wi',
                'app',
                'ash',
                'bra',
                'cah',
                'chz',
                'clc',
                'czy',
                'ew_bel',
                'ew_clr',
                'ew_elb',
                'ew_msu',
                'ew_ner',
                'ew_nwm',
                'ew_sbe',
                'ew_spo',
                'ew_ver',
                'gbe',
                'gen',
                'ith',
                'kalb',
                'kaoh',
                'kavl',
                'kbgm',
                'ken',
                'keri',
                'khef',
                'kiag',
                'kmsp',
                'kmss',
                'kno',
                'krme',
                'ma_nort',
                'mbe',
                'mil',
                'mlc',
                'mst',
                'nc_eaw',
                'nc_fla',
                'nc_gre',
                'nj_mil',
                'noa',
                'ny_genn',
                'ny_lans',
                'nysm_burt',
                'nysm_laur',
                'pa_big',
                'per',
                'pfo',
                'pno',
                'scl',
                'sdl',
                'um_cso',
                'va_win',
                'vco',
                'wal',
                'wi_tre',
                'wlk',
                'wtr',
            ]

            return !this.location.weather_station || oldIds.includes(this.location.weather_station.id);
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
    },
}
</script>

<style lang="scss">
.location-details {
    .btn-primary {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
        font-size: 18px;
        line-height: 21px;

        svg {
            font-size: 21px;
            vertical-align: text-top;
        }

        /*@media (min-width:992px) {
            font-size:24px;
            line-height: 27px;
            svg {
                font-size:27px;
            }
        }*/

    }
}

.location__actions {
    @media (max-width: 600px) {
        button {
            font-size: 0;

            svg {
                font-size: 24px;
            }
        }
    }

}

</style>
