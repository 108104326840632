<template>
    <div class="thinning-dataset-edit-modal">

        <b-button v-b-modal="`thinning-dataset-edit-modal-${id}`">
            <!--slot to enable custom buttons-->
            <slot/>
        </b-button>
        <data-form-modal :modal-id="`thinning-dataset-edit-modal-${id}`"
                         title="Edit Fruit Growth Rate Dataset"
                         save-button-label="Save"
                         :field-list="fieldList"
                         :field-model="fieldModel"
                         :model-instance="modelInstance"
                         :on-submit-handler="onSubmit" />
    </div>
</template>

<script>
import thinningDatasetFields from "@/models/thinningDatasetFields";
import DataFormModal from "@/components/DataFormModal.vue";
import _ from "lodash";

export default {
    name: "ThinningDatasetEditModal",
    components: {DataFormModal},
    props: {
        location_id: String,
        modelInstance: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            fieldList: [
                'tree_count',
                'cluster_count',
                'flower_count',
                'static',
                // inject Dynamic fields and sort
                ...Object.keys(this.getFlowerClustersCountedPerTree()).sort(),
                'target_fruit_per_tree'
            ],
            fieldModel: {
                // inject Dynamic fields
                fields: {
                    ...thinningDatasetFields.fields,
                    ...this.getFlowerClustersCountedPerTree()
                }
            },
        }
    },
    computed: {
        id() {
            return Math.floor(Math.random() * 100000)
        }
    },
    methods: {
        // Helper for adding Flower Clusters Counted Per Tree fields (# of fields vary, so must be done dynamically)
        getFlowerClustersCountedPerTree() {
            const fields = {}
            for (let i = 1; i <= this.modelInstance.tree_count; i++) {
                fields[`clusters_counted_${i}`] = {label: `Tree #${i}`, type: 'number'}
            }
            return fields
        },
        updateCalculatedFields(model) {
            let clusters_counted = [];
            for (let i = 1; i <= model['tree_count']; i++) {
                let count = model[`clusters_counted_${i}`]
                if (!count) count = 0
                clusters_counted.push(_.toNumber(count))
            }

            const average_clusters_counted = _.mean(clusters_counted)
            const potential = model['flower_count'] * average_clusters_counted

            model['clusters_counted_per_tree'] = average_clusters_counted
            model['potential_fruit_per_tree'] = potential
        },
        async onSubmit(modelInstance) {
            this.updateCalculatedFields(modelInstance)
            console.log(modelInstance)

            // remove all fields with undefined values
            const saveModel = _.transform(modelInstance, (result, value, key) => {
                if (value !== undefined) {
                    if (!isNaN(value)) {
                        result[key] = +value
                    } else {
                        result[key] = value
                    }
                }
            })

            // id is set when editing and null when creating
            let id = saveModel.id
            if (id) {
                await this.$api.updateThinningDataset(this.location_id, saveModel)
                this.$toasted.info('Dataset Edited')
            } else {
                await this.$api.createThinningDataset(this.location_id, saveModel)
                // update dataset with id
                await this.$api.updateThinningDataset(this.location_id, {id: this.location_id})
                this.$toasted.info('Dataset Created')
            }
            this.$router.go(0)
        }
    },
}
</script>

<style scoped>

</style>