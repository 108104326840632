<template>
    <div class="heading d-flex flex-column flex-lg-row flex-wrap justify-content-between align-items-center">
        <template v-if="title">
            <h1 class="my-2">
                {{ title }}
            </h1>
        </template>
        <div class="heading__buttons">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Heading',
    props: {
        title: String,
        tag: String,
    },
    data() {
        return {}
    },
}
</script>


<style lang="scss">
.heading {
    //margin-top: 1em;
    padding: 0 15px 10px 15px;
    background-color: var(--accent-color-lighten-30);
    border: solid 6px var(--accent-color-lighten-20);
}

.heading__buttons .btn {
    @media(max-width: 991px) {
        margin: 10px auto;
    }
}

@media (max-width: 575px) {
    .heading__buttons {
        margin-top: 6px;
        text-align: center;
    }
}
</style>