<template>
    <div class="home">

        <location-list v-if="loggedIn"/>

        <div v-else>
            <div class="text-center">
                <h1>Welcome to Malusim</h1>
                <div class="welcome-logo">
                    <img src="/malusim-icon-large.png"/>
                </div>
                <b-button variant="info"
                          class="me-2"
                          to="/login">
                    Login
                </b-button>
                <b-button variant="info"
                          class="me-2"
                          to="/signup">
                    Sign Up
                </b-button>
            </div>
        </div>

    </div>
</template>

<script>

import Vue from 'vue'
import LocationList from "@/pages/LocationList.vue"

export default Vue.extend({
    components: {LocationList},
    computed: {
        loggedIn() {
            return !!this.$store.state.currentUser.email
        },
    }
})

</script>
<style lang="scss">
.welcome-logo {
    margin: 20px auto 30px auto;
    img {
        width:240px;
    }
}
</style>
