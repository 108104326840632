import * as _ from 'lodash';
import moment from 'moment';

import { ChartColors } from "./chart-colors";

/**
 * Service for misc. calculations in the Carbohydrate Thinning Model
 */
export class Carbohydrate {

  public endDate: string; // NB: this is bound in the view - location-carbohydrate.html
  newaDataRows: any[];
  newaDataRowsForTable: any[];
  dataHasLoaded: boolean;

  public chart: any;
  chartUpdate: boolean = false;

  constructor()
  {
    this.initCharts();
    this.endDate = new Date().toISOString();
    this.newaDataRows = [];
    this.newaDataRowsForTable = [];
    this.dataHasLoaded = false;
  }

  // get data from NEWA server
  async fetchResults(location) {
    let self = this;

    if (!location['weather_station'] || !location['green_tip_date'] || !location['bloom_date']) {
      self.dataHasLoaded = true;
      return;
    }

    const station = location['weather_station']['id'];
    const apiEndDate = encodeURIComponent(moment(this.endDate).format('M/D/YYYY'));
    const greenTipDate = encodeURIComponent(moment(location['green_tip_date']).format('M/D/YYYY'));
    const bloomDate = encodeURIComponent(moment(location['bloom_date']).format('M/D/YYYY'));
    const pctSpursFlowering = location['pct_spurs_flowering']

    let url = `https://newa.nrcc.cornell.edu/newaTools/process_input_new?type=apple_thin`
      + `&stn=${station}`
      + `&accend=${apiEndDate}`
      + `&greentip=${greenTipDate}`
      + `&bloom=${bloomDate}`
      + `&percentflowerspurs=${pctSpursFlowering}`
      + `&output=json`;

    self.newaDataRows = [];

    let res = await fetch(url)
    let data = (await res.json()).data
      self.newaDataRows = data.slice(0);
      self.newaDataRowsForTable = _.reverse(data.slice(0));
      self.dataHasLoaded = true;
      self.calculateResults(location);
  }

  // calculate additional result variables
  calculateResults(location: Location) {
    if (!this.newaDataRows.length) {
      return;
    }
    let self = this;

    this.refreshCarbChartData(location);
  }

  initCharts() {
    this.chart = {
      title: { text: 'Carbohydrate Balance' },
      chart: { type: 'areaspline' },
      yAxis: {
        title: { text: '' },
        min: -100,
        max: 100
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 7 * 24 * 3600 * 1000, // one week
      },
      legend: {
        layout: 'horizontal',
      },
      plotOptions: {
        areaspline: {
          fillColor: 'rgba(112, 161, 61, .5)',
          marker: {
            radius: 4,
            lineWidth: 1
          }
        }
      },
      series: [ { data: [] } ]
    };
  }

  refreshCarbChartData(location) {

    console.log('refreshCarbChartData');

    const balances = _.map(this.newaDataRows, (row) => {
      // console.log(row);
      return [moment(row.date).valueOf(), parseFloat(row.avg7day)]
    });

    this.chart.series = [{
      name: 'Balance',
      data: balances,
      color: ChartColors.darkBlue
    }];

    const endValue = moment(this.endDate).add(-1, 'days').valueOf();
    const lastValue = moment(_.last(this.newaDataRows)[0]).valueOf();

    // add plot band for predicted values
    this.chart.xAxis.plotBands = [{
      from: endValue,
      to: lastValue,
      zIndex:5,
      className:'predicted-band',
      label: {
        text: '(Predicted)',
        verticalAlign: 'middle'
      }
    }];

    // add plot band for each spray date
    _.each(location.spray_records, rec => {
      const stageLabel = {
        'bloom': 'Bloom',
        'petal_fall': 'Petal Fall',
        '8_10': '8-10mm',
        '10_12': '10-12mm',
        '12_14': '12-14mm',
        '14_16': '14-16mm',
        '16_18': '16-18mm',
        '18_20': '18-20mm',
        '20_22': '20-22mm'
      }[rec.stage];
      this.chart.xAxis.plotBands.push({
        from: moment(rec.date).valueOf(),
        to: moment(rec.date).valueOf(),
        className:'spray-date-band',
        label: {
          text: `Spray<br/>(${stageLabel})`,
          // rotation: 270,
          borderWidth: 4,
          verticalAlign: 'top'
        }
      });
    });

    // console.log(this.chart.xAxis.plotBands);

    this.chartUpdate = true; // trigger redraw
  }
}
