import Vue from 'vue'
import VueMoment from 'vue-moment'
import Toasted from 'vue-toasted'

import App from './App.vue'
import router from './router'
import store from './store'
// import { StatusBar, Style } from '@capacitor/status-bar';

import Utils from './providers/Utils'

import {
  AlertPlugin,
  BadgePlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  IconsPlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  PaginationPlugin,
  PopoverPlugin,
  ProgressPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin
} from 'bootstrap-vue'

import Highcharts from "highcharts";
import HighchartsVue from 'highcharts-vue'
import exporting from 'highcharts/modules/exporting'
import offlineExporting from 'highcharts/modules/offline-exporting'

// needed to enable highcharts exporting
exporting(Highcharts)
offlineExporting(Highcharts)

import * as VueGoogleMaps from 'vue2-google-maps'
import api from '@/providers/Api'
import VueMq from 'vue-mq'
import { Capacitor } from "@capacitor/core";

Vue.use(Toasted, { duration: 5000 })

Vue.use(VueMoment)

Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormFilePlugin)
Vue.use(CollapsePlugin)
Vue.use(CardPlugin)
Vue.use(PopoverPlugin)
Vue.use(TabsPlugin)
Vue.use(AlertPlugin)
Vue.use(BadgePlugin)
Vue.use(ModalPlugin)
Vue.use(ListGroupPlugin)
Vue.use(IconsPlugin)
Vue.use(ProgressPlugin)

Vue.use(HighchartsVue)

Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyCPP-EjQf6jul9uUrRmOCspfvwdyEouuiI', libraries: 'places' } })

Vue.config.productionTip = false

Utils.registerComponents()
Utils.addCustomMixin('api')

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 540,
    md: 720,
    lg: 1024,
    xl: 1140,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

new Vue({
  router,
  store,
  // @ts-ignore
  api,
  render: h => h(App)
}).$mount('#app')

// if (Capacitor.isNativePlatform()) {
//   const setStatusBarStyleDark = async () => {
//     await StatusBar.setStyle({ style: Style.Dark });
//   };
//   const setStatusBarColor = async () => {
//     await StatusBar.setBackgroundColor({ color: '#ffa725' });
//   };
//   setStatusBarStyleDark()
//   setStatusBarColor()
// }
