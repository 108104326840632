<template>
    <spinner-until :condition="fbUserLoaded" style="height: 100%">
        <div id="app">

            <header class="header">
                <nav-bar />
            </header>

            <div class="content content-outer">
                <router-view />
            </div>

            <main-footer />

        </div>
    </spinner-until>
</template>

<script>
import Vue from 'vue'
import Api from '@/providers/Api'
import MainFooter from "@/components/MainFooter.vue";
import NavBar from "@/components/NavBar.vue";
import SpinnerUntil from "@/components/SpinnerUntil.vue";

export default Vue.extend({
    name: 'app',
    components: {SpinnerUntil, NavBar, MainFooter},
    computed: {
        // don't show anything until fb auth has been initialized!
        fbUserLoaded() {
            // NB: email is undefined if fb auth hasn't kicked in; email is null if no user logged in
            return this.$store.state.currentUser.email !== undefined &&
                this.$store.state.currentDbUser?.email !== undefined
        }
    },
    beforeCreate() {
        Api.initApp()
    },
    created() {
        document.title = 'Malusim'
    }
})

</script>

<style lang="scss">

@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-vue/dist/bootstrap-vue.css';

/* base styles for app */
@import '../sass/base';

.content-outer {
  min-height: 100%;
}

</style>
