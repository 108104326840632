/**
 * Model class for User profile validation
 */

import {email, minLength, maxLength, numeric, required, sameAs} from 'vuelidate/lib/validators'

export default {
    fields: {
        // firstName: {
        //   label: 'First Name',
        //   type: 'text',
        //   validations: {required}
        // },
        // lastName: {
        //   label: 'Last Name',
        //   type: 'text',
        //   validations: {required}
        // },
        email: {
            label: 'Email',
            type: 'text',
            validations: {required, email},
            invalidFeedback: {email: 'Must be a valid email address'}
        },
        emailReadonly: {
            label: 'Email',
            type: 'text',
            disabled: true
        },
        password: {
            label: 'Password',
            type: 'password',
            validations: {required, minLengthPassword: minLength(6)},
            invalidFeedback: {minLengthPassword: 'Passwords must be 6 or more characters'}
        },
        passwordConfirm: {
            label: 'Confirm Password',
            type: 'password',
            validations: {required, sameAsPassword: sameAs('password')},
            invalidFeedback: {sameAsPassword: 'Passwords much match'}
        },
        // phone: {
        //   label: 'Phone Number',
        //   type: 'text',
        //   validations: {required}
        // },
        // address: {
        //   label: 'Address',
        //   type: 'text',
        //   validations: {required}
        // },
        // city: {
        //   label: 'City',
        //   type: 'text',
        //   validations: {required}
        // },
        // state: {
        //   label: 'State',
        //   type: 'select',
        //   options: [
        //     'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'VI', 'WA', 'WV', 'WI', 'WY'
        //   ],
        //   validations: {required}
        // },
        // zip: {
        //   label: 'Zip Code',
        //   type: 'text',
        //   validations: {
        //     required,
        //     numeric,
        //     minLength: minLength(5),
        //     maxLength: maxLength(5)
        //   }
        // },
    }
}