<template>
    <div class="thinning-dataset-summary">
        <breadcrumb>
            <router-link :to="{name: 'fruitGrowthRateDetails', params: {location_id: location_id}}">
                Fruit Growth Rate Model
            </router-link>
        </breadcrumb>
        <div class="clearfix"/>
        <heading title="Fruit Growth Rate Model Data" />

        <spinner-until :condition="loaded">
            <b-container>
                <b-row>
                    <b-table striped bordered responsive
                             :items="thinning_measurements"
                             :fields="fields"
                             label-sort-asc=""
                             label-sort-desc=""
                             label-sort-clear="">
                        <template #head()="data">
                            <span v-html="data.label"></span>
                        </template>

                        <template v-slot:cell()="row">
                            <div v-if="row.field.key === 'created'" class="d-flex">
                                {{ formatDate(row.item[row.field.key]) }}
                            </div>

                            <div v-else class="d-flex">
                                {{ row.item[row.field.key] ? row.item[row.field.key].toFixed(2) : '' }}
                            </div>
                        </template>
                    </b-table>

                    <h3>Show Columns:</h3>
                    <b-checkbox v-for="field in fields" :key="field.key" v-model="field.isChecked" @input="toggleColumn($event, field)">
                        <span v-html="field.label"></span>
                    </b-checkbox>
                </b-row>
            </b-container>
        </spinner-until>
    </div>
</template>

<script>
import SpinnerUntil from "@/components/SpinnerUntil.vue";
import Utils from "@/providers/Utils";

export default {
    name: "ThinningDatasetSummary",
    components: {SpinnerUntil},
    props: {
        location_id: String
    },
    data() {
        return {
            measurements: [],
            fields: [
                {
                    key: 'created',
                    label: 'Date',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'days_since_last',
                    label: 'Days Between',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'mean_of_top_15_by_diameter',
                    label: 'Mean of Top 15',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'mean_of_all_by_diameter',
                    label: 'Mean of All',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'mean_of_top_15_by_diameter_growth',
                    label: 'Mean of Top 15',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'half_mean_of_top_15_by_diameter_growth',
                    label: '50% Fastest Growing',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'count_of_over_50_pct_fastest',
                    label: '> 50% Top',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'count_of_under_50_pct_fastest',
                    label: '< 50% Top',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'count_of_measured_fruit',
                    label: 'Measured',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'predicted_pct_setting',
                    label: 'Based on Original # of Fruit',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
            ],
        }
    },
    async mounted() {
        this.measurements = await this.$api.getThinningMeasurements(this.location_id)
    },
    computed: {
        loaded() {
            return !!this.measurements
        },
        // flatten summary info into measurements so it can be used by table
        thinning_measurements() {
            if (this.loaded) {
                return this.measurements.map(meas => ({...meas, ...meas.summary}))
            }
            return []
        }
    },
    methods: {
        toggleColumn(event, field) {
            field.thClass = field.tdClass = event ? '' : 'd-none'
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
    }
}
</script>

<style scoped>

</style>