<template>
    <div class="user-signup">
        <data-form :field-groups="fieldGroups"
                   :field-model="userModel"
                   save-button-label="Sign up"
                   :top-submit-button="false"
                   :bottom-submit-button="true"
                   :on-submit-handler="signup"
                   title="Sign up" />

        <div class="clearfix"/>

        <div>Already have an account? Log in
            <router-link style="display: inline" to="/login">here</router-link>
        </div>
    </div>
</template>

<script>

import UserFields from "@/models/User"

export default {
    name: 'UserSignup',
    data() {
        return {
            userModel: UserFields,
            fieldGroups: [
                ['email', 'password', 'passwordConfirm'],
            ]
        }
    },
    methods: {
        async signup(modelInstance) {
            await this.$api.signupUser(this, modelInstance.email, modelInstance.password)
            if (this.$store.state.currentUser.email) {
                this.$toasted.success('Logged in')
            } else {
                this.$toasted.error('Unable to sign up')
            }
        }
    }
}
</script>

<style lang="scss">

</style>
