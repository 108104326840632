<template>
    <div class="spray-record-edit-modal">

        <b-button v-b-modal="`spray-record-edit-modal-${id}`">
            <!--slot to enable custom buttons-->
            <slot />
        </b-button>
        <data-form-modal :modal-id="`spray-record-edit-modal-${id}`"
                         title="Create Spray Record"
                         save-button-label="Save"
                         :field-list="fieldList"
                         :field-model="fieldModel"
                         :model-instance="modelInstance"
                         :on-submit-handler="onSubmit" />
    </div>
</template>

<script>
import SprayRecordFields from "@/models/sprayRecordFields";
import DataFormModal from "@/components/DataFormModal.vue";
import _ from "lodash";

export default {
    name: "SprayRecordEditModal",
    components: {DataFormModal},
    props: {
        location_id: String,
        modelInstance: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            fieldList: [
                'date',
                'stage',
                'chemical'
            ],
            fieldModel: SprayRecordFields,
        }
    },
    computed: {
        id() {
            return Math.floor(Math.random() * 100000)
        }
    },
    methods: {
        async onSubmit(modelInstance) {
            // remove all fields with undefined values
            const saveModel = _.transform(modelInstance, (result, value, key) => {
                if (value !== undefined) result[key] = value
            })

            const email = this.$store.state.currentUser.email

            // id is set when editing and null when creating
            let id = saveModel.id
            if (id) {
                await this.$api.updateItem(this, `locations/${email}/locations/${this.location_id}/spray_records`, id, saveModel)
                this.$toasted.info('Spray Record Edited')
            } else {
                id = (await this.$api.createItem(this, `locations/${email}/locations/${this.location_id}/spray_records`, saveModel)).id
                // update spray_record with id
                await this.$api.updateItem(this, `locations/${email}/locations/${this.location_id}/spray_records`, id, {id: id})
                this.$toasted.info('Spray Record Created')
            }
            this.$router.go(0)
        }
    },
}
</script>

<style scoped>

</style>