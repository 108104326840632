import store from '@/store'

import {initializeApp} from "firebase/app"
import {Capacitor} from "@capacitor/core"
import {
    addDoc,
    CACHE_SIZE_UNLIMITED,
    collection,
    deleteDoc,
    doc,
    enableIndexedDbPersistence,
    getDoc,
    getDocs,
    initializeFirestore,
    query,
    setDoc,
    updateDoc
} from "firebase/firestore"
import {
    createUserWithEmailAndPassword,
    deleteUser,
    getAuth,
    indexedDBLocalPersistence,
    initializeAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updatePassword
} from "firebase/auth"
import _ from "lodash"
import moment from "moment";

// --------------------------------------------

const firebaseConfig = {
    apiKey: "AIzaSyAh1wPE855XZAZtThmyGE6bQ_k4newmcm4",
    authDomain: "malusim-capacitor-app.firebaseapp.com",
    projectId: "malusim-capacitor-app",
    storageBucket: "malusim-capacitor-app.appspot.com",
    messagingSenderId: "352574533040",
    appId: "1:352574533040:web:ba8c14df63c4e35298ba75",
    measurementId: "G-19CRH5H0CX"
}

const api = {
    db: null,

    initApp() {
        if (window.location.hostname.includes('localhost') && !Capacitor.isNativePlatform()) {
            store.state.devMode = true
        }

        const app = initializeApp(firebaseConfig)
        if (Capacitor.isNativePlatform()) {
            initializeAuth(app, {
                persistence: indexedDBLocalPersistence
            })
        }
        this.db = initializeFirestore(app, {
            cacheSizeBytes: CACHE_SIZE_UNLIMITED
        })

        // load remembered user state from Firebase
        getAuth().onAuthStateChanged(async user => {
            await this.setUserState(user)
        })

        enableIndexedDbPersistence(this.db)
            .catch((err) => {
                if (err.code === 'failed-precondition') {
                    window.alert('Multiple copies of the app are open at the same time. Close other tabs/windows for offline mode to function correctly')
                } else if (err.code === 'unimplemented') {
                    window.alert('Your device does not support offline mode')
                }
            })
    },

    async deleteUser(vue, user) {
        try {

            await this.deleteItem(null, 'users', { id: user.email })

            await deleteUser(user)
            await this.setUserState(null)

            await vue.$router.replace('/')
            vue.$toasted.info('Account deleted')

        } catch (error) {
            console.log(error)
            vue.$toasted.error('There was an error deleting your account. Try logging out an logging back in.')
        }
    },

    async signupUser(vue, email, password) {
        const auth = getAuth()
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            const user = userCredential.user

            // create DB record for user
            await this.setItem(this, `users`, email, {email: email})

            await this.setUserState(user)

            await vue.$router.replace('/')

            return userCredential
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                vue.$toasted.error('Email already in use')
            }
        }
    },

    async loginUser(vue, email, password) {
        const auth = getAuth()
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            vue.$toasted.success('Logged in')
            const user = userCredential.user
            await this.setUserState(user)
            await vue.$router.replace('/')
            return userCredential
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async logoutUser(vue) {
        const auth = getAuth()
        try {
            await signOut(auth)
            await this.setUserState(null)
            await vue.$router.replace('/')
            vue.$toasted.info('Signed Out')
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async setUserState(fbUser) {
        if (fbUser) {
            store.state.currentUser = fbUser
            store.state.currentDbUser = await this.getItem(null, 'users', fbUser.email)
            localStorage.setItem('email', fbUser.email)
        } else {
            store.state.currentUser = {email: null}
            store.state.currentDbUser = {email: null}
            localStorage.setItem('email', null)
        }
        if (!store.state.currentDbUser) {
            await this.setItem(this, `users`, fbUser.email, {email: fbUser.email})
            await this.setUserState(fbUser)
        }
    },

    async isAdmin() {
        return await this.getItem(null, `admins`, store.state.currentUser.email) !== undefined
    },

    async isSuperAdmin() {
        return store.state.currentUser.email === 'test@example.com'
    },

    async refreshDbUserState() {
        store.state.currentDbUser = await this.getItem(null, 'users', store.state.currentUser.email)
    },

    userLoggedIn() {
        return !!store.state.currentUser.email
    },

    currentUserId() {
        return store.state.currentUser.email
    },

    async resetPassword(vue, email) {
        const auth = getAuth()
        try {
            // send password reset email which redirects to login
            return await sendPasswordResetEmail(auth, email, {url: `${window.location.origin}/login`})
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async updatePassword(vue, newPassword) {
        const auth = getAuth()
        try {
            return await updatePassword(auth.currentUser, newPassword)
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async sendEmail(vue, to, message, showToast = true) {
        const email = {
            to: to,
            message: message
        }

        const docRef = doc(collection(this.db, 'mail'))
        setDoc(docRef, email).then(() => {
            if (showToast) vue.$toasted.success('Email Sent')
        }, reason => {
            if (showToast) vue.$toasted.error(reason)
        })
    },

    async getItem(vue, collectionPath, id) {
        const docRef = doc(this.db, collectionPath, id)
        const docSnap = await getDoc(docRef)
        return docSnap.data()
    },

    // for use with DataTableLocalSort
    async getAllItems(vue, collectionPath) {

        let q = query(collection(this.db, collectionPath))
        const documentSnapshots = await getDocs(q)

        const items = []
        documentSnapshots.forEach((doc) => {
            // tack the doc ID on to the rest of the doc data
            const data = _.cloneDeep(doc.data())
            data.id = doc.id
            items.push(data)
        })

        return new Promise(resolve => resolve(items))
    },

    // updates a Firestore doc, setting the given attributes (not overwriting the whole doc)
    async updateItem(vue, collectionPath, id, attrs) {
        const docRef = doc(this.db, collectionPath, id)
        await updateDoc(docRef, attrs)
    },

    async setItem(vue, collectionPath, id, attrs) {
        const docRef = doc(this.db, collectionPath, id)
        await setDoc(docRef, attrs)
    },

    async createItem(vue, collectionPath, item) {
        const docRef = await addDoc(collection(this.db, collectionPath), item)
        return docRef
    },

    async deleteItem(vue, collectionPath, item) {
        const response = await deleteDoc(doc(this.db, collectionPath, item.id))
        return response
    },

    async getLocations() {
        return await this.getAllItems(null, `locations/${store.state.currentUser.email}/locations`)
    },

    async getLocation(location_id) {
        return await this.getItem(null, `locations/${store.state.currentUser.email}/locations`, location_id)
    },

    async deleteLocation(location) {
        return await this.deleteItem(null, `locations/${store.state.currentUser.email}/locations`, location)
    },

    async getSprayRecords(location_id) {
        return await this.getAllItems(null, `locations/${store.state.currentUser.email}/locations/${location_id}/spray_records`)
    },

    async deleteSprayRecord(location_id, spray_record) {
        return await this.deleteItem(null, `locations/${store.state.currentUser.email}/locations/${location_id}/spray_records`, spray_record)
    },

    async getThinningDataset(location_id) {
        return await this.getItem(null, `thinning_datasets/${store.state.currentUser.email}/thinning_datasets`, location_id)
    },

    async createThinningDataset(location_id, dataset) {
        await this.setItem(null,`thinning_datasets/${store.state.currentUser.email}/thinning_datasets`, location_id, dataset)
    },

    async updateThinningDataset(location_id, dataset) {
        await this.updateItem(null,`thinning_datasets/${store.state.currentUser.email}/thinning_datasets`, location_id, dataset)
    },

    async getThinningMeasurements(location_id) {
        return await this.getAllItems(null, `thinning_datasets/${store.state.currentUser.email}/thinning_datasets/${location_id}/measurements`)
    },

    async getThinningMeasurement(location_id, measurement_id) {
        return await this.getItem(null, `thinning_datasets/${store.state.currentUser.email}/thinning_datasets/${location_id}/measurements`, measurement_id)
    },

    async createThinningMeasurement(location_id, measurement) {
        const meas = await this.setItem(null, `thinning_datasets/${store.state.currentUser.email}/thinning_datasets/${location_id}/measurements`, measurement.id, measurement)
        return await this.getItem(null, `thinning_datasets/${store.state.currentUser.email}/thinning_datasets/${location_id}/measurements`, measurement.id)
    },

    async updateThinningMeasurement(location_id, measurement) {
        measurement.summary = Object.assign({}, measurement.summary) // prevent Firestore error re: saving custom object
        const meas = Object.assign({}, measurement)
        await this.updateItem(null,`thinning_datasets/${store.state.currentUser.email}/thinning_datasets/${location_id}/measurements`, measurement.id, meas)
    },

    async deleteThinningMeasurement(location_id, measurement) {
        return await this.deleteItem(null, `thinning_datasets/${store.state.currentUser.email}/thinning_datasets/${location_id}/measurements`, measurement)
    },

    async getIrrigationRecordsCollection(location_id) {
        return await this.getAllItems(null, `irrigation_records/${store.state.currentUser.email}/irrigation_records/${location_id}/records`)
    },

    async createIrrigationRecord(location_id, record) {
        return await this.createItem(null, `irrigation_records/${store.state.currentUser.email}/irrigation_records/${location_id}/records`, record)
    },

    async updateIrrigationRecord(location_id, record) {
        return await this.updateItem(null, `irrigation_records/${store.state.currentUser.email}/irrigation_records/${location_id}/records`, record.id, record)
    }


}

export default api
