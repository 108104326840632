<template>
    <div class="thinning-measurement-details">
        <breadcrumb>
            <router-link :to="{name: 'fruitGrowthRateDetails', params: {location_id: location_id}}">
                Fruit Growth Rate Model
            </router-link>
        </breadcrumb>
        <div class="clearfix"/>
        <AboutModal topic="measurement_details"/>
        <heading title="Enter Measurements"/>

        <spinner-until :condition="loaded">
            <b-tabs v-model="currentTreeTab">
                <b-tab v-for="t in thinningDataset.tree_count" :key="`tree-tab-${t}`" :title="`T${t}`">
                    <b-tabs v-model="currentClusterTab">
                        <b-tab v-for="c in thinningDataset.cluster_count" :key="`cluster-tab-${c}`" :title="`C${c}`">
                            <div v-for="f in thinningDataset.flower_count" :key="`fruitlet-field-${f}`">
                                <b-row class="mt-3 thinning-measurement-details__entry">
                                    <b-col cols="6">
                                        <label>Fruitlet #{{ f }}</label>
                                        <b-input type="number" step="0.1" class="float-left"
                                                 v-model="measurementData[`${t}_${c}_${f}`]"/>
                                    </b-col>
                                    <b-col v-if="isNative" class="pt-3">
                                        <b-button class="float-right" size="sm"
                                                  @click="startListening($event, t, c, f)">
                                            <b-icon icon="mic-fill"/>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                            <br>
                            <b-button size="sm"
                                      @click="clearValues($event, t, c)">
                                Clear Measurements
                                <b-icon icon="backspace-fill"/>
                            </b-button>
                        </b-tab>
                    </b-tabs>
                </b-tab>
                <b-tab title="Dbg">
                    Listening: {{ isListening }}
                    <br/>
                    Speech state: {{ speechState }}
                </b-tab>
            </b-tabs>
        </spinner-until>
    </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'
import moment from 'moment'

import SpinnerUntil from "@/components/SpinnerUntil.vue"
import {Speech} from '@/providers/speech'
import {Capacitor} from "@capacitor/core";
import AboutModal from "@/components/AboutModal.vue";

export default {
    name: "ThinningMeasurementDetails",
    components: {AboutModal, SpinnerUntil},
    props: {
        location_id: String,
        measurement_id: String,
        is_new: {type: Boolean, default: false},
    },
    data() {
        return {
            location: {},
            thinningDataset: {},
            thinningMeasurement: {},

            speech: {},
        }
    },
    methods: {
        clearValues(event, t, c) {
            this.$bvModal.msgBoxConfirm('Clear measurements for this cluster?', {
              okVariant: 'danger'
            }).then(async value => {
                if (value) {
                    for (let f = 1; f <= this.thinningDataset.flower_count; f++) {
                        Vue.set(this.thinningMeasurement.data,`${t}_${c}_${f}`, null)
                    }
                }
            })
        },
        startListening(event, t, c, f) {
            // console.log(event, t, c, f)
            if (Capacitor.getPlatform() == 'web') {
                alert('Speech functions are only available in the Android or iOS apps.')
            } else {
                this.speech.beginListening(t, c, f)
            }
        },
        numerifyValues() {
            for (let t = 1; t <= this.thinningDataset.tree_count; t++) {
                for (let c = 1; c <= this.thinningDataset.cluster_count; c++) {
                    for (let f = 1; f <= this.thinningDataset.flower_count; f++) {
                        if (this.thinningMeasurement.data) {
                            const val = this.thinningMeasurement.data[`${t}_${c}_${f}`]
                            const num = _.toNumber(val)
                            // if (t == 2 && c == 2 && f == 2) {
                            //     console.log('val', JSON.stringify(val), 'num', num)
                            // }
                            if (val != '' && _.isNumber(num)) {
                                Vue.set(this.thinningMeasurement.data,
                                    `${t}_${c}_${f}`,
                                    num)
                            }
                        }
                    }
                }
            }
        }
    },
    computed: {
        isNative() {
            return Capacitor.isNativePlatform()
        },
        loaded() {
            return !!this.thinningDataset && !!this.thinningDataset.tree_count && !!this.thinningMeasurement
        },
        measurementData() {
            return this.thinningMeasurement?.data ? this.thinningMeasurement.data : {}
        },
        isListening() {
            return this.speech?.isListening
        },
        speechState() {
            return this.speech.currentSpeechState ? this.speech.currentSpeechState : {}
        },
        // the tab indexes are bound to the speech state, so that speech actions can change tabs -
        // BUT, we do not implement the setters - user can change tabs manually and re-start speech recognition.
        // NB: speechState indexes are 1-based; tab indexes are 0-based.
        currentTreeTab: {
            get() {
                return this.speechState.t - 1
            },
            set(val) {
            }
        },
        currentClusterTab: {
            get() {
                return this.speechState.c - 1
            },
            set(val) {
            }
        },
    },
    async mounted() {
        this.speech = new Speech()

        this.location = await this.$api.getLocation(this.location_id)
        this.thinningDataset = await this.$api.getThinningDataset(this.location_id)
        if (this.is_new) {
            this.thinningMeasurement = await this.$api.createThinningMeasurement(this.location_id, {
                created: moment(this.measurement_id).toISOString(),
                id: this.measurement_id,
                data: {}
            })
        } else {
            this.thinningMeasurement = await this.$api.getThinningMeasurement(this.location_id, this.measurement_id)
        }

        console.log(this.thinningMeasurement)

        this.speech.init(this.location, this.thinningDataset, this.thinningMeasurement)
    },
    watch: {
        'thinningMeasurement.data': {
            immediate: false,
            deep: true,
            handler: function (newVal, oldVal) {
                this.numerifyValues()
                this.$api.updateThinningMeasurement(this.location_id, this.thinningMeasurement)
            }
        }
    }
}
</script>

<style lang="scss">
.thinning-measurement-details__entry {
  align-items: end;

  button {
    border: solid 1px #5A7A29;
  }
}
</style>