<template>
    <div class="export-measurement-data">
        <breadcrumb>
            <router-link :to="{name: 'fruitGrowthRateDetails', params: {location_id: location_id}}">
                Fruit Growth Rate Model
            </router-link>
        </breadcrumb>
        <div class="clearfix"/>
        <heading title="Export Measurement Data"/>

        <mark>
            To save the measurement data, select the entire contents of the field below, copy it,
            and paste it into a text file or an application that accepts data as tab-separated values.<br>
            [E] denotes excessive growth rate for that measurement (greater than 1.5 mm/day).<br>
            [O] denotes an outlier (outside 2 standard deviations of all growth rates).<br>
        </mark>
        <textarea v-model="tsvForExport" class="mt-3" />
    </div>
</template>

<script>

import moment from 'moment'

export default {
    name: "ExportMeasurementData",
    props: {
        location_id: String
    },
    data() {
        return {
            dataset: {},
            thinning_measurements: []
        }
    },
    async mounted() {
        this.dataset = await this.$api.getThinningDataset(this.location_id)
        this.thinning_measurements = await this.$api.getThinningMeasurements(this.location_id)
    },
    computed: {
        tsvForExport() {
            // header
            let tsv = 'Tree\tCluster\tFruitlet'

            // dates
            this.thinning_measurements.forEach(meas => {
                tsv += `\t${moment(meas.created).format('MM/DD/YYYY')}`
            })
            tsv += '\n'

            //rows
            for (let t = 1; t <= this.dataset.tree_count; t++) {
                for (let c = 1; c <= this.dataset.cluster_count; c++) {
                    for (let f = 1; f <= this.dataset.flower_count; f++) {
                        tsv += `${t}\t${c}\t${f}`
                        this.thinning_measurements.forEach(meas => {
                            let val = meas.data[`${t}_${c}_${f}`];
                            if (meas.data_analysis[`${t}_${c}_${f}_outlier`]) {
                                val += '[O]'
                            }
                            if (meas.data_analysis[`${t}_${c}_${f}_growth_rate_excessive`]) {
                                val += '[E]'
                            }
                            tsv += '\t' + (val ? val : '');
                        });
                        tsv += '\n';
                    }
                }
            }

            return tsv
        }
    }
}
</script>

<style lang="scss">
    .export-measurement-data textarea {
        height:60vh;
        width:100%;
        max-height: 800px;
        @media (min-width:768px) {

        }
    }

</style>