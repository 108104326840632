<template>
    <div class="about-modal" :class="{'show-topic-label': showTopicLabel}">
        <template v-if="asLink">
            <a v-b-modal="topic">
                {{ header }}
            </a>
        </template>
        <template v-else>
            <b-button v-b-modal="topic">
                <b-icon icon="question-circle"/>
                &nbsp;
                <span v-if="showTopicLabel">
                    {{ title }}
                </span>
                <span v-else>
                    Help
                </span>
            </b-button>
        </template>

        <b-modal :id="topic" title="Help" hide-footer>
            <h1>
                <b-icon :icon="icon"/>
                {{ header }}
            </h1>
            <div v-html="body"/>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "AboutModal",
    props: {
        topic: String,
        showTopicLabel: {
            type: Boolean,
            default: false
        },
        asLink: {type: Boolean, default: false}
    },
    computed: {
        content() {
            return this.about_content[this.topic]
        },
        title() {
            return this.content.title
        },
        header() {
            return this.content.header
        },
        icon() {
            return this.content.icon
        },
        body() {
            return this.content.body
        }
    },
    data() {
        return {
            about_content: {
                carbohydrate: {
                    title: 'Carbohydrate Thinning Model',
                    header: 'Apple Carbohydrate Thinning Model',
                    icon: 'cloud-sun-fill',
                    body: `
<p>
    This model generates a thinning recommendation based on weather conditions and the resulting tree carbohydrate
    status for the location.
</p>
<p>To run the model, select the current date.</p>

<h2>About this model</h2>

<p>
    The Cornell apple carbohydrate thinning model was developed by Alan Lakso at Cornell University and adapted for
    practical use by Terence Robinson at Cornell University. The model, using fundamental tree physiology, estimates
    over a season from bud break the carbohydrate production by an apple tree with a set description and the total
    demands for growth for both the crop and the tree growth. It calculates a balance of supply to demand each day
    of the season using the weather data that is entered from the chosen weather station.
</p>

<p>
    It should be noted that the model does not attempt to model any specific variety or training system. The general
    carbohydrate balance it calculates has been found to correlate well with tree sensitivity to natural drop and with
    sensitivity to chemical thinners. Cool sunny periods of good carbohydrate supply leads to reduced natural drop and
    less response to thinners. Cloudy hot periods give carbohydrate deficits and lead to stronger natural drop and
    stronger response to thinners. The four-day running average is used since studies and observations have shown that
    the apple tree does not respond to just one or two days of good or bad weather, but has a capacity to respond more
    slowly to changes in weather.
</p>

<p>
    The thinning recommendations given for each date are based on the forecasted carbohydrate balance over the next 4
    days in the future. Thus if a spray is applied today, the weather over the next 4 days is taken into account when
    giving a thinning recommendation. The thinning recommendations are based on the best research available and are
    given as a guide to help define the proper rate of hormone type thinning chemicals (NAA, BA and Carbaryl) when
    sprayed with a given carbohydrate status. However, since they are based on forecasted weather and biological
    systems which we don't completely understand they are inherently imprecise. Users of the carbohydrate model should
    use the recommendations at their own risk. Cornell University is not responsible for the actual outcome of thinning
    sprays which are influenced by many additional variables including individual spraying methods.
</p>

<table>
    <caption>Table 1. Decision rules for using the output of the carbohydrate model to adjust chemical thinning rate
    </caption>
    <tr>
        <th>4-day Av. Carb. Balance</th>
        <th>Thinning Recommendation</th>
    </tr>
    <tr>
        <td>> 20g/day</td>
        <td>Increase Chemical Thinner Rate by 30%</td>
    </tr>
    <tr>
        <td>0g/day to 20g/day</td>
        <td>Increase Chemical Thinner Rate by 15%</td>
    </tr>
    <tr>
        <td>0g/day to -20g/day</td>
        <td>Apply Standard Chemical Thinner Rate</td>
    </tr>
    <tr>
        <td>-20g/day to -40g/day</td>
        <td>Decrease Chemical Thinner Rate by 15%</td>
    </tr>
    <tr>
        <td>-40g/day to -60 g/day</td>
        <td>Decrease Chemical Thinner Rate by 30%</td>
    </tr>
    <tr>
        <td>-60g/day to -80 g/day</td>
        <td>Decrease Chemical Thinner Rate by 50%</td>
    </tr>
    <tr>
        <td>< than -80g/day</td>
        <td>Do not thin (many fruits will fall off naturally)</td>
    </tr>
</table>

                    `
                },
                irrigation: {
                    title: 'Apple Irrigation Model',
                    header: 'Apple Irrigation Model',
                    icon: 'droplet-fill',
                    body: `
<p>
    This model calculates water balance and evapotranspiration (ET)
    for the location based on observed and forecasted rainfall, as
    well as irrigation applied.
</p>
<p>To run the model, select the date on which you might wish to irrigate.</p>
<p>You can also enter rainfall and irrigation values, which will be stored in your Irrigation Records.</p>
<p>
    <strong>NOTE:</strong>
    After entering or updating rainfall or irrigation values, you must select the
    <strong>Save Entered Data</strong> button to record these in your Irrigation Records.
</p>
<p>
    Whenever you run the model, the results will be copied to the Irrigation Records for the
    current location. You should run the model at least once a week if you wish to maintain
    complete records for the season.
    <!--
    Note that you will need to enter the appropriate values for rainfall and
    irrigation each time you run the model, as values you have entered previously may be
    overwritten in the irrigation
    records when the model is re-run (if they are within the past seven days covered by the
    model).
    -->
</p>
<p>
    The calculated water volume needed by the orchard is displayed in gallons/acre (Water Balance).
    The model will calculate and display the amount of water needed for that orchard for each of
    the last 7 days, and for the upcoming 6 days.
</p>
<p>
    To avoid oversaturating the soil when irrigation water is applied just before or just after
    a predicted large rainfall event (0.5 inches or more), we suggest not applying the
    recommended irrigation amount for one day before the event or for three days after the event.
</p>

<h2>About this model</h2>

<p>
    The apple ET model was developed at Cornell University due to inadequacies in our climate in the standard method.
    The standard method estimates apple water use as a fraction of the water use of a standard reference grass,
    adjusting seasonally for relative canopy development. The grass model, calculated by an equation (called the
    Penman-Monteith equation) based on the physics of evaporating surfaces like a grass, is strongly controlled
    by sunlight radiation but not very sensitive to temperature and humidity. Apple tree water use, however, for
    complex reasons is much more sensitive to temperature and humidity.
</p>

<p>
    In humid climates where we can have days of similar sunlight radiation but greatly different temperatures and
    humidities, the standard method gives some large errors. Consequently, it was felt necessary to adapt the
    Penman-Monteith equation directly for apple rather than relating apple to grass which is a poor model of apples.
</p>

<p>
    Also, the apple model is a general model and does not take into account variations in soil characters, tree
    conditions (except for young trees) or cultural practices. It runs on weather data from the selected weather station
    that may or may not be representative of any specific orchard, especially in rainfall.
    Please use it with these precautions in mind. The model was tested against direct measurements in New York,
    but will be continuously tested and refined over time.
</p>
                    `
                },
                irrigation_records: {
                    title: 'Irrigation Records',
                    header: 'Irrigation Records',
                    icon: 'droplet-fill',
                    body: `
<p>
    This page displays irrigation and rainfall data collected for the location during the current growing
    season. This data includes values that you input for irrigation applied and rainfall, as well as other values
    calculated by the Irrigation Model.
</p>
<p>
    Since much of this data is provided by the Irrigation Model, it is important that you run the model
    at least once a week, as it provides only one week's worth of actual data (as well as one week of predicted
    data). If you run the model as prescribed, at any point in the season you will have actual data for every
    day up to that point, with only one week of predicted data at the end of the records.
</p>
<p>
    In cases where the Irrigation Model's data does not agree with the values that you input (for example, if
    you are using your own weather station to collect rainfall values rather than the weather station used by
    the model), your values will not be overwritten in the Irrigation Records.
</p>
                    `
                },
                location: {
                    title: 'Locations',
                    header: 'Locations',
                    icon: 'geo-alt-fill',
                    body: `
<p>
    A Location is an orchard or block that contains a single apple variety.
    You need to create a Location for each area that you want to track
    separately with the analysis tools.
</p>
<p>
    If you have different spacings, flow rates, or soil types within the
    same variety block, you should create a separate Location for each.
</p>
<p>
    Because analysis parameters such as Green Tip Date can change from year
    to year, you need to create a new set of Locations every year.
    But this is easy to do, using the Clone function.
</p>
                    `
                },
                location_details: {
                    title: 'Location Details',
                    header: 'Location Details',
                    icon: 'geo-alt-fill',
                    body: `
<p>Use this page to view the details of this location, and to run the different models on it.</p>
<p>
    Each location needs to be updated once a year, to reflect changes in
    attributes such as Green Tip Date. Rather than editing each location,
    you should use the Clone function to create a new copy of the location,
    so that data from previous years can still be reviewed if desired.
</p>
                    `
                },
                measurement_details: {
                    title: 'Enter Measurements',
                    header: 'Entering measurements',
                    icon: 'mic-fill',
                    body: `
<p>
    Use the Enter Measurements form to record fruitlet diameters for designated clusters.
</p>
<p>
    <strong>TIP:</strong>
    If you are conducting the first measurement of the season for the current location, enter 0.1 for
    any fruitlets that are not present in a cluster (for example, if the cluster size is 5 and there are
    only three fruitlets in the current cluster, enter 0.1 for Fruitlet #4 and Fruitlet #5). This will
    enhance the accuracy of the Fruit Growth Rate Model calculations.
</p>
<h3>Using Speech Recognition</h3>
<p>
    If you are using the iOS or Android app, you can use speech recognition to enter measurement data
    (this feature is not available if you are using a browser, even if the browser is running on an
    iOS or Android device).
</p>
<p>
    <strong>NOTE:</strong>
    You can enter measurement data using speech recognition even if you do not have a network connection, but
    in order to do so you must load the measurement screen while you are still on the network. Data that you enter
    will be saved temporarily on the device, and synced when you return to the network.
    Also, the Fruit Growth Rate Model calculations will not update when you are off network - you must re-run them
    using the Recalculate button when you return to the network.
</p>

<p>
    To start entering measurements, click the
    <ion-icon name='mic' is-active="false"></ion-icon>
    microphone icon beside the appropriate input field. The app will prompt you with the current cluster and
    fruitlet number.
</p>
<p>
    After you speak the value (as a whole or decimal number with no units - e.g. "twelve point five"),
    the app will repeat what it understood, then prompt you for the next fruitlet. If you have completed
    a cluster, it will prompt you for the next cluster.
</p>
<p>
    <strong>TIP:</strong> In the Android app, you may need to "trick" the speech recognizer when inputting whole
    numbers, by adding a decimal to them - for example, instead of saying "two", say "two point zero".
    This applies even for inputs that cannot have decimals - for example, when saying a cluster number.
</p>
<p>
    Besides speaking numbers, you can use the following commands to control the input process:
</p>
<ul>
    <li>
        <strong>"Stop"</strong> will cause the app to stop listening for measurements - you can restart
        recognition by clicking on a microphone icon.
    </li>
    <li>
        <strong>"Skip"</strong> will prompt you for a cluster to go to - in case you want to switch
        clusters before the current one is complete.
    </li>
    <li>
        <strong>"Back"</strong> will prompt you to re-enter the last measurement, in case there was a recognition
        error. Note that you can only go back one step this way - if you need to go to an earlier fruitlet, use
        the <strong>stop</strong> command and restart on the desired input.
    </li>
    <li>
        <strong>"Help"</strong> will repeat all entered measurements for the current cluster.
    </li>
</ul>
                    `
                },
                spray_records: {
                    title: 'Spray Records',
                    header: 'Spray Records',
                    icon: 'eyedropper',
                    body: `
<p>
    Use this page to track chemical applications for this location. This data will be displayed
    in conjunction with the models to help you see the impact of spraying.
</p>
                    `
                },
                thinning: {
                    title: 'Fruit Growth Rate Model',
                    header: 'Fruit Growth Rate Model',
                    icon: 'basket',
                    body: `
<p>
    This model calculates predicted fruit yields for the location,
    based on diameter measurements that you perform on designated fruitlet clusters.
</p>
<p>
    To use the model, you need to record measurements of clusters at designated time intervals.
    The predictions will become more refined as you record more measurements.
</p>
                    `
                },
                privacy_policy: {
                    title: 'Malusim Privacy Policy',
                    header: 'Privacy Policy',
                    icon: '',
                    body: `
<p>
    Your privacy is very important to us. Accordingly, we have developed this notice in order for you to
    understand how we collect, use, communicate, disclose and make use of personal information. We are
    committed to conducting our business in accordance with these principles in order to ensure that the
    confidentiality of personal information is protected and maintained.
</p>
<p>
    Malusim is designed to allow users to collect and maintain various data related to apple orchards, from
    locations as specified by the user.
</p>
<p>
    This notice describes how Malusim handles data and personal information that users provide to us, or
    that we collect from you through the use of our website (https://malusim.org) or the
    Malusim smartphone application.
</p>
<p>
    Effective Date of Privacy Notice
</p>
<p>
    The privacy notice was last revised on April 25, 2019.
</p>
<p>
    What information we gather
</p>
<p>
    Information we gather: We automatically collect certain information from you when you visit our website
    and/or use our Malusim smartphone application. This data is used in conjunction with Google Analytics
    to gather metrics on your usage of the website, including geographic location of visitors, pathways
    navigated through the website or smartphone application. As part of Google Analytics, we also gather
    device and browser-related information.
</p>
<p>
    Information you may provide:
</p>
<p>
    Personal information is voluntarily supplied by you when you register and submit data to Malusim.
    "Personal Information", in this case, means information such as name, e-mail address, zip code, Internet
    Protocol (IP) addresses, and any other information that would allow someone to identify you or contact
    you.
    By registering, you allow us to associate your data with a unique identifier, so that you can track data
    that you have submitted.
    Orchard-related data submitted by users is the only information we collect, apart from
    “personal information,” as described above. All details of this information are available to Malusim researchers.
</p>
<p>
    How we use your information
</p>
<p>
    Malusim may release non-personally-identifying information in the aggregate, e.g., by publishing
    reports on trends, or maps. To protect the privacy of users, maps will only be publicly released with
    general location information, such as to the zip code level. By submitting content to Malusim, you
    grant Malusim a world-wide, royalty-free, and non-exclusive license to reproduce, modify, adapt and
    publish the content solely for the purpose of research.
</p>
<p>
    Users can view all of their personal data and submitted information but data from other users can only be
    viewed if released publicly by Malusim. If you send us a request (for example via a help support email
    or via one of our feedback mechanisms), we reserve the right to publish your request anonymously with our
    response in order to help us clarify or respond to your request or to help us support other users.
</p>
<p>
    With whom we share your information
</p>
<p>
    Malusim discloses potentially personally-identifying and non-personally-identifying information only
    to those of its researchers and affiliated organizations that (i) need to know that information in order
    to analyze it or to provide services available at Malusim’s websites, and (ii) that have agreed not to
    disclose it to others. Malusim will not rent or sell potentially personally-identifying and
    personally-identifying information to anyone.
</p>
<p>
    Other than to its researchers and affiliated organizations, Malusim discloses potentially
    personally-identifying and personally-identifying information only when required to do so by law, or when
    Malusim believes in good faith that disclosure is reasonably necessary to protect the property or
    rights of Malusim, third parties, or the public at large.
</p>
<p>
    Cookies
</p>
<p>
    Cookies are pieces of data that we store on your computer to tie your information to the Malusim
    program. Cookies allow you to store your login information, so that you will not be required to log in
    each time you visit the Malusim web site.
</p>
<p>
    Internet browsers allow you to determine whether or not to accept cookies (check under the preferences or
    options in the browser menu). If you decide not to accept cookies, you can still use the Malusim
    website, but you will need to re-enter login information each time you visit.
</p>
<p>
    Protecting your information
</p>
<p>
    No method of transmitting over the internet or storing electronic data is 100% secure, but this site and
    its mobile app have measures in place to help protect against the loss, misuse, or alteration of the
    information
    that is under our control. We will protect personal information by reasonable security safeguards against
    loss or theft, as well as unauthorized access, disclosure, copying, use or modification.
</p>
<p>
    Email Marketing
</p>
<p>
    If you are a registered user of Malusim, we may occasionally send you an email to tell you about new
    features or solicit your feedback but your email address and contact information will not be sold,
    distributed, or shared outside the Malusim research team and its management collaborators.
</p>
<p>
    External Website Links
</p>
<p>
    From time to time, we will link to external websites that we neither own nor control. Cornell University
    is not responsible for the content, privacy practices, or web accessibility needs on these websites.
</p>
<p>
    Special Notice for EU Residents
</p>
<p>
    If you are located within the European Economic Area (European Union, Norway, Liechtenstein, and Iceland),
    we acknowledge the rights granted to you under the General Data Protection Regulation (GDPR).
</p>
<p>
    These rights may include:
</p>
<ul>
    <li>Right to access your information held by us</li>
    <li> Right to correct inaccurate or incorrect information about you</li>
    <li>Right to the erasure of your information when it is no longer necessary for us to retain it</li>
    <li>Right to restrict processing of your personal information in specific situations</li>
    <li>Right to object to processing your information, including sending you communications that may be
        considered direct-marketing materials
    </li>
    <li>Right to object to automated decision-making and profiling, where applicable</li>
    <li>Right to complain to a supervisory authority in your jurisdiction within the EU</li>
</ul>
<p>
    Please contact us with any questions, concerns, or if you wish to exercise any of these rights:
    gdpr@cornell.edu
</p>
<p>
    Contact Information
</p>
<p>
    If you have questions about our privacy notice or the privacy practices it describes, please contact:
    privacy@cornell.edu.
</p>
                    `
                },
            }
        }
    }
}
</script>

<style lang="scss">
.about-modal {
    display: flex;
    justify-content: flex-end;
}

</style>