import * as _ from 'lodash';
import moment from 'moment';
import Api from '@/providers/Api'

import { ChartColors } from "./chart-colors";

/**
 * Service for misc. calculations in the Irrigation Model
 */
export class Irrigation {

    public endDate: string; // NB: this is bound in the view - location-irrigation.html
    newaDataRows: any[];
    resultRows: any[];
    missingData: boolean = false;

    public location: any;
    public irrigationRecords: any;

    public chart: any;
    chartUpdate: boolean = false;

    public recordsChart: any;
    recordsChartUpdate: boolean = false;

    constructor() {
        this.initCharts();
        this.endDate = new Date().toISOString();
        this.newaDataRows = [];
    }

    // subscribe to the irrigation_records for this location so we can update them in tandem with
    // running the irrigation model
    async setLocation(location) {
        this.location = location;

        this.refreshIrrigationRecordsChartData = _.debounce(this.refreshIrrigationRecordsChartData.bind(this), 300);

        this.irrigationRecords = await Api.getIrrigationRecordsCollection(this.location.id)
        this.refreshIrrigationRecordsChartData();
    }

    // get ET and rainfall data from NEWA server
    async fetchResults() {
        let self = this;

        if (!this.location['weather_station'] || !this.location['orchard_age'] || !this.location['green_tip_date']) {
            self.missingData = true;
            return;
        }

        const station = this.location['weather_station']['id'];
        const apiEndDate = encodeURIComponent(moment(this.endDate).format('M/D/YYYY'));
        const greenTipDate = encodeURIComponent(moment(this.location['green_tip_date']).format('M/D/YYYY'));

        let url = `https://newa.nrcc.cornell.edu/newaTools/process_input_new?type=apple_et`
            + `&stn=${station}`
            + `&accend=${apiEndDate}`
            + `&greentip=${greenTipDate}`
            + `&output=json`;

        const res = await fetch(url)
        const data = (await res.json()).data
        self.newaDataRows = data;
        self.missingData = false;
        self.calculateResults();
    }

    // calculate additional result variables
    calculateResults() {
        console.log('calculateResults');
        if (!this.newaDataRows.length) {
            return;
        }
        let self = this;

        const model_tree_density = 518;		    	// trees per acre
        const gallons_per_liter = 0.264172052;	// gallons of water per liter
        const gallons_per_inch = 27154;   		 	// gallons of water per acre for 1" precip
        const age_factors = {
            '1': {'scaleEt': 0.10, 'scaleRain': 0.05},
            '2': {'scaleEt': 0.30, 'scaleRain': 0.25},
            '3': {'scaleEt': 0.60, 'scaleRain': 0.35},
            '4': {'scaleEt': 0.80, 'scaleRain': 0.50},
            'mature': {'scaleEt': 1.0, 'scaleRain': 0.70}
        };

        const trees_per_acre = this.location['trees_per_acre'];
        const orchard_age = this.location['orchard_age'];

        let water_balance_cumulative = 0;
        let orchard_et_cumulative = 0;
        let rainfall_cumulative = 0;
        let irrigation_cumulative = 0;

        if (!this.resultRows) {
            this.resultRows = [];
        }

        for (let i = 0; i < this.newaDataRows.length; i += 1) {
            if (!this.resultRows[i]) {
                this.resultRows[i] = {};
            }
            let row = this.resultRows[i];
            // data row: date (month day), modeled et in liters per tree, precipitation in inches
            row.date = this.newaDataRows[i][0];

            row.model_et = this.newaDataRows[i][1] === -999 ? null : this.newaDataRows[i][1];	// -999 is missing value code, display as dash
            //metl = $.isNumeric(met_raw) ? parseFloat(met_raw) : 0.0; 			// assume bad or missing values to be zero in ensuing calculations

            if (!row.rainfall_edited) {
                // only take rainfall from NEWA results if user has not input a new value
                row.rainfall_in = this.newaDataRows[i][2] === -999 ? null : this.newaDataRows[i][2];	// -999 is missing value code, display as dash
                //raini = $.isNumeric(rain_raw) ? parseFloat(rain_raw) : 0.0; 		// assume bad or missing values to be zero in ensuing calculations
            }

            // if there is an existing record for this date whose rainfall/irrigation was edited,
            // copy that value to the current row, unless the current value has been edited
            const record: any = _.find(this.irrigationRecords, {date: row.date});
            if (record && record.rainfall_edited && !row.rainfall_edited) {
                row.rainfall_in = record.rainfall_in;
                row.rainfall_edited = true;
            }
            if (record && record.irrigation_edited && !row.irrigation_edited) {
                row.irrigation = record.irrigation;
                row.irrigation_edited = true;
            }

            // make sure we don't have undefined values (FB doesn't like this)
            if (!row.rainfall_in) {
                row.rainfall_in = 0;
            }
            if (!row.irrigation) {
                row.irrigation = 0;
            }

            let orchard_et = row.model_et * (1.0 / trees_per_acre) * model_tree_density * age_factors[orchard_age]['scaleEt'];
            row.orchard_et_per_tree = this.nullIfNaN(orchard_et * gallons_per_liter);
            row.orchard_et_gpa = this.nullIfNaN(row.orchard_et_per_tree * trees_per_acre);
            row.rainfall_gpa = row.rainfall_in * gallons_per_inch * age_factors[orchard_age]['scaleRain'];
            row.water_balance_daily = this.nullIfNaN(row.rainfall_gpa + row.irrigation - row.orchard_et_gpa);
            row.irrigation_time = this.nullIfNaN(row.irrigation / this.location['application_rate']);

            // for cumulative values, update the accumulator and the row value...
            water_balance_cumulative = Math.min((water_balance_cumulative + row.water_balance_daily), 0.0);
            row.water_balance_cumulative = water_balance_cumulative;
            orchard_et_cumulative += row.orchard_et_gpa;
            row.orchard_et_cumulative = orchard_et_cumulative;
            rainfall_cumulative += row.rainfall_gpa;
            row.rainfall_cumulative = rainfall_cumulative;
            irrigation_cumulative += row.irrigation;
            row.irrigation_cumulative = irrigation_cumulative;

            row.should_irrigate = row.water_balance_cumulative < -10000;

            row.irrigation_recommendation = 'Do not irrigate';
            if (row.should_irrigate) {
                const cumulative_deficit = -10000 - row.water_balance_cumulative;
                if (!this.location['application_rate']) {
                    row.irrigation_recommendation = '[Application Rate not set for Location]';
                } else {
                    const irrigation_time = cumulative_deficit / this.location['application_rate'];
                    row.irrigation_recommendation = 'Irrigate at ' +
                        this.location['application_rate'] +
                        ' gal/hr for ' +
                        Math.floor(irrigation_time * 60) +
                        ' minutes';
                }
            }
        }

        this.refreshIrrigationChartData();
        this.updateIrrigationRecords();
    }

    updateIrrigationRecords() {
        console.log('updateIrrigationRecords for ' + this.location.id);
        // console.log('results rows', this.resultRows)
        for (let i = 0; i < this.resultRows.length; i += 1) {
            let row = this.resultRows[i];
            if (row) {
                const record: any = _.find(this.irrigationRecords, {date: row.date});
                if (!record) {
                    console.log(row);
                    Api.createIrrigationRecord(this.location.id, row);
                } else {
                    record.irrigation_recommendation = row.irrigation_recommendation;
                    // copy edited rainfall/irrigation values to existing record and update
                    if (row.rainfall_edited) {
                        record.rainfall_in = row.rainfall_in;
                        record.rainfall_edited = true;
                    }
                    if (row.irrigation_edited) {
                        record.irrigation = row.irrigation;
                        record.irrigation_edited = true;
                    }
                    Api.updateIrrigationRecord(this.location.id, record);
                }
            }
        }
    }

    initCharts() {
        this.chart = this.getChartConfig();
        this.recordsChart = this.getChartConfig();
    }

    getChartConfig() {
        return {
            title: {
                text: 'Water Balance'
            },
            chart: {},
            yAxis: {
                title: {
                    text: ''
                }
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 7 * 24 * 3600 * 1000, // one week
            },
            legend: {
                layout: 'horizontal',
            },
            plotOptions: {},
            series: [ // NB: must create correct number of series here to do multi-series chart (??)
                {data: []},
                {data: []},
                {data: []},
                {data: []},
                {data: []},
            ]
        };
    }

    refreshIrrigationChartData() {

        const daily_balances = _.map(this.resultRows, (row) => [moment(row.date).valueOf(), row.water_balance_daily]);
        const cumulative_balances = _.map(this.resultRows, (row) => [moment(row.date).valueOf(), row.water_balance_cumulative]);
        const cumulative_ets = _.map(this.resultRows, (row) => [moment(row.date).valueOf(), row.orchard_et_cumulative]);
        const cumulative_rainfalls = _.map(this.resultRows, (row) => [moment(row.date).valueOf(), row.rainfall_cumulative]);
        const cumulative_irrigations = _.map(this.resultRows, (row) => [moment(row.date).valueOf(), row.irrigation_cumulative]);

        this.chart.series = [
            {
                type: 'column',
                name: 'Daily Balance',
                color: ChartColors.blue,
                data: daily_balances
            },
            {
                type: 'spline',
                name: 'Cumulative Balance',
                color: ChartColors.orange,
                data: cumulative_balances
            },
            {
                type: 'spline',
                name: 'Cumulative ET',
                color: ChartColors.red,
                data: cumulative_ets
            },
            {
                type: 'spline',
                name: 'Cumulative Rainfall',
                color: ChartColors.primary,
                data: cumulative_rainfalls
            },
            {
                type: 'spline',
                name: 'Cumulative Irrigation',
                color: ChartColors.border,
                data: cumulative_irrigations
            }
        ];

        const endValue = moment(this.endDate).valueOf();
        const lastValue = moment(_.last(this.resultRows).date).valueOf();

        // add plot band for predicted values
        this.chart.xAxis.plotBands = [{
            from: endValue,
            to: lastValue,
            className: 'predicted-band',
            zIndex: 5,
            label: {
                text: '(Predicted)',
                verticalAlign: 'middle',
            }
        }];

        this.chartUpdate = true; // trigger redraw
    }

    refreshIrrigationRecordsChartData() {

        const daily_balances = _.map(this.irrigationRecords, (row) => [moment(row.date).valueOf(), row.water_balance_daily]);
        const cumulative_balances = _.map(this.irrigationRecords, (row) => [moment(row.date).valueOf(), row.water_balance_cumulative]);
        const cumulative_ets = _.map(this.irrigationRecords, (row) => [moment(row.date).valueOf(), row.orchard_et_cumulative]);
        const cumulative_rainfalls = _.map(this.irrigationRecords, (row) => [moment(row.date).valueOf(), row.rainfall_cumulative]);
        const cumulative_irrigations = _.map(this.irrigationRecords, (row) => [moment(row.date).valueOf(), row.irrigation_cumulative]);

        this.recordsChart.series = [
            {
                type: 'column',
                name: 'Daily Balance',
                color: ChartColors.blue,
                data: daily_balances
            },
            {
                type: 'spline',
                name: 'Cumulative Balance',
                color: ChartColors.orange,
                data: cumulative_balances
            },
            {
                type: 'spline',
                name: 'Cumulative ET',
                color: ChartColors.red,
                data: cumulative_ets
            },
            {
                type: 'spline',
                name: 'Cumulative Rainfall',
                color: ChartColors.primary,
                data: cumulative_rainfalls
            },
            {
                type: 'spline',
                name: 'Cumulative Irrigation',
                color: ChartColors.border,
                data: cumulative_irrigations
            }
        ];

        this.recordsChartUpdate = true; // trigger redraw
    }

    // convert NaN to null (Firebase can't store NaN values)
    nullIfNaN(val) {
      return _.isNaN(val) ? null : val;
    }


}



