import {required} from "vuelidate/lib/validators"
import _ from "lodash"

function calcTreeRowVolume(model) {
    let trv = Math.round(
        model.tree_width *
        model.tree_height *
        (43560 / model.spacing_between_rows) *
        (0.7 / 1000)
    )
    if (trv === Infinity || trv === undefined || _.isNaN(trv)) {
        trv = null
    }
    model.tree_row_volume = trv
}

function calcTreesPerAcre(model) {
    let tpa = Math.round(
        43560 / (model.spacing_in_row * model.spacing_between_rows)
    )
    if (tpa === Infinity) {
        tpa = null
    }
    model.trees_per_acre = tpa
}

function calcEmittersPerAcre(model) {
    let epa = Math.round(
        43560 / (model.emitter_spacing_in_row * model.emitter_spacing_between_rows)
    )
    if (epa === Infinity) {
        epa = null
    }
    model.emitters_per_acre = epa
}

function calcApplicationRate(model) {
    let ar = Math.round(
        model.emitters_per_acre * model.emitter_flow
    )
    if (ar === Infinity) {
        ar = null
    }
    model.application_rate = ar
}

export default {
    fields: {
        id: {
            label: 'ID',
            type: 'text',
        },
        application_rate: {
            icons: ['irrigation'],
            label: 'Application Rate',
            type: 'number',
            disabled: true,
        },
        block: {
            label: 'Block',
            type: 'text',
        },
        bloom_date: {
            icons: ['carb'],
            label: 'Bloom Date',
            type: 'date',
        },
        emitter_flow: {
            icons: ['irrigation'],
            label: 'Emitter Flow',
            type: 'number',
            onChange(model) {
                calcApplicationRate(model)
            }
        },
        emitter_spacing_between_rows: {
            icons: ['irrigation'],
            label: 'Emitter Between Row Spacing',
            type: 'number',
            onChange(model) {
                calcEmittersPerAcre(model)
                calcApplicationRate(model)
            }
        },
        emitter_spacing_in_row: {
            icons: ['irrigation'],
            label: 'Emitter In Row Spacing',
            type: 'number',
            onChange(model) {
                calcEmittersPerAcre(model)
                calcApplicationRate(model)
            }
        },
        emitters_per_acre: {
            icons: ['irrigation'],
            label: 'Emitters Per Acre',
            type: 'number',
            disabled: true,
        },
        farm: {
            icons: ['irrigation', 'fruit','carb'],
            label: 'Farm',
            type: 'text',
            validations: {required},
        },
        green_tip_date: {
            icons: ['irrigation', 'carb'],
            label: 'Green Tip Date',
            type: 'date',
        },
        pct_spurs_flowering: {
            icons: ['carb'],
            label: 'Percent Spurs Flowering',
            type: 'select',
            options: {'1': '0-25%', '2': '26-50%', '3': '51-75%', '4': '76-100%'},
        },
        orchard_age: {
            icons: ['irrigation'],
            label: 'Orchard Age',
            type: 'select',
            options: {'1': '1 year old', '2': '2 years old', '3': '3 years old', '4': '4 years old', 'mature': 'Mature'},
        },
        owner: {
            label: 'Owner',
            type: 'text',
            validations: {required},
        },
        spacing_between_rows: {
            icons: ['irrigation'],
            label: 'Between Row Spacing',
            type: 'number',
            onChange(model) {
                calcTreesPerAcre(model)
            }
        },
        spacing_in_row: {
            icons: ['irrigation'],
            label: 'In Row Spacing',
            type: 'number',
            onChange(model) {
                calcTreesPerAcre(model)
            }
        },
        trees_per_acre: {
            icons: ['irrigation'],
            label: 'Trees Per Acre',
            type: 'number',
            disabled: true,
        },
        tree_row_volume: {
            label: 'Tree Row Volume',
            type: 'number',
            disabled: true,
        },
        tree_height: {
            label: 'Tree Height',
            type: 'number',
            onChange(model) {
                calcTreeRowVolume(model)
            }
        },
        tree_width: {
            label: 'Tree Width',
            type: 'number',
            onChange(model) {
                calcTreeRowVolume(model)
            }
        },
        variety: {
            icons: ['irrigation', 'fruit', 'carb'],
            label: 'Variety',
            type: 'text',
            validations: {required},
        },
        water_holding_capacity: {
            icons: ['irrigation'],
            label: 'Water Holding Capacity',
            type: 'number',
        },
        // Don't save! Computed from water_holding_capacity
        pct_water_holding_capacity: {
            label: '20% Water Holding Capacity',
            type: 'number',
        },
        weather_station_title_dummy: {
            icons: ['irrigation', 'carb'],
            label: 'Weather Station',
            type: 'text',
            disabled: true
        },
        year: {
            icons: ['irrigation', 'fruit', 'carb'],
            label: 'Year',
            type: 'number',
            validations: {required},
        },
        weather_station: {
            label: '',
            type: 'weather_station_map',
            onChange(model) {
                if (model.weather_station.title) {
                    model.weather_station_title_dummy = model.weather_station.title
                }
            }
        },
    },
    requiredForThinning(field) {
        return [
            'year',
            'farm',
            'variety',
        ].includes(field)
    },
    requiredForIrrigation(field) {
        return [
            'year',
            'farm',
            'variety',
            'weather_station_title_dummy',
            'green_tip_date',
            'orchard_age',
            'application_rate',
            'water_holding_capacity',
            'spacing_in_row',
            'spacing_between_rows',
            'trees_per_acre',
            'emitter_spacing_in_row',
            'emitter_spacing_between_rows',
            'emitters_per_acre',
            'emitter_flow',
        ].includes(field)
    },
    requiredForCarbohydrate(field) {
        return [
            'year',
            'farm',
            'variety',
            'weather_station_title_dummy',
            'green_tip_date',
            'bloom_date',
            'pct_spurs_flowering',
        ].includes(field)
    }
}
