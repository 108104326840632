<template>
    <div class="location-edit-modal">
        <b-button v-b-modal="`location-edit-modal-${id}`">
            <!--slot to enable custom buttons-->
            <slot />
        </b-button>
        <data-form-modal :modal-id="`location-edit-modal-${id}`"
                         title="Create Location"
                         save-button-label="Save"
                         :field-list="fieldList"
                         :field-model="fieldModel"
                         :model-instance="modelInstance"
                         :on-submit-handler="onSubmit">

            <template v-slot:fieldSuffix="slotProps">
                <div class="data-form-modal-row__icons">
                    <b-icon-basket v-if="(slotProps.field.icons || []).includes('fruit')" />
                    <b-icon-cloud-sun-fill v-if="(slotProps.field.icons || []).includes('carb')" />
                    <b-icon-droplet-fill v-if="(slotProps.field.icons || []).includes('irrigation')" />
                </div>
            </template>

            <hr>
            <table class="table table-striped card-info">
                <thead>
                <tr>
                    <th>Soil Type</th>
                    <th>Water Holding Capacity</th>
                    <th>Recommended days per week to irrigate (indicative)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Sand</td>
                    <td class="text-end">27,154 gal/acre</td>
                    <td class="text-center">7</td>
                </tr>
                <tr>
                    <td>Sandy loam</td>
                    <td class="text-end">54,308 gal/acre</td>
                    <td class="text-center">5</td>
                </tr>
                <tr>
                    <td>Loam</td>
                    <td class="text-end">81,462 gal/acre</td>
                    <td class="text-center">3</td>
                </tr>
                <tr>
                    <td>Clay loam</td>
                    <td class="text-end">103,185 gal/acre</td>
                    <td class="text-center">2</td>
                </tr>
                <tr>
                    <td>Clay</td>
                    <td class="text-end">108,616 gal/acre</td>
                    <td class="text-center">2</td>
                </tr>
                </tbody>
            </table>
        </data-form-modal>
    </div>
</template>

<script>
import LocationFields from "@/models/locationFields";
import DataFormModal from "@/components/DataFormModal.vue";
import _ from "lodash";

export default {
    name: "LocationEditModal",
    components: {DataFormModal},
    props: {
        modelInstance: {
            type: Object,
            default: () => ({year: new Date().getFullYear()})
        }
    },
    data() {
        return {
            fieldList: [
                'year',
                'farm',
                'block',
                'variety',
                'weather_station_title_dummy',
                'weather_station',
                'green_tip_date',
                'bloom_date',
                'pct_spurs_flowering',
                'orchard_age',
                'spacing_in_row',
                'spacing_between_rows',
                'trees_per_acre',
                'tree_width',
                'tree_height',
                'tree_row_volume',
                'emitter_spacing_in_row',
                'emitter_spacing_between_rows',
                'emitters_per_acre',
                'emitter_flow',
                'application_rate',
                'water_holding_capacity'
            ],
            fieldModel: LocationFields,
        }
    },
    computed: {
        id() {
            return Math.floor(Math.random() * 100000)
        }
    },
    methods: {
        async onSubmit(modelInstance) {
            // remove all fields with undefined values
            const saveModel = _.transform(modelInstance, (result, value, key) => {
                if (value !== undefined) result[key] = value
            })

            // Add hidden owner field
            const email = this.$store.state.currentUser.email
            saveModel.owner = email

            // id is set when editing and null when creating
            let id = saveModel.id
            if (id) {
                await this.$api.updateItem(this, `locations/${email}/locations`, id, saveModel)
                this.$toasted.info('Location Edited')
            } else {
                id = (await this.$api.createItem(this, `locations/${email}/locations`, saveModel)).id
                // update location with id
                await this.$api.updateItem(this, `locations/${email}/locations`, id, {id: id})
                this.$toasted.info('Location Created')
            }
            await this.$router.replace({name: 'locationDetails', params: {location_id: id}})
        }
    },
}
</script>

<style lang="scss">

.data-form-modal-row {
  display: grid;
  grid-template-columns: 3fr 1fr;

  .required-marker {
    line-height: 16px;
  }

  .data-form-modal-row__icons {
    justify-self: end;
    align-self: end;
  }
}

.weather-station-map {
  button, .custom-select {
    margin-bottom: 1em;
  }

  .vue-map-container {
    width: 100% !important;
    height: 0 !important;
    padding-top: 100%;
    position: relative;

    .vue-map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

</style>