<template>
    <div class="view-table">
        <table class="table table-striped">
            <tbody>
            <tr v-for="key in fields" :key="key">
                <td class="text-end table-labels">{{ getLabel(key) }}</td>
                <td class="text-left">
                    <div :class="getValueCssClass(key)">
                        <slot :name="key" v-bind="{ key:key, value:getValue(key) }">
                            <span v-html="getValue(key)" />
                        </slot>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

import Vue from 'vue'
import * as _ from 'lodash'
import moment from 'moment'

export default Vue.extend({
    name: 'ViewTable',
    props: {
        model: Object,
        data: Object,
        exclude: Array,
        fields: Array,
    },
    methods: {
        getLabel(key) {
            const field = this.model.fields[key]
            if (this.model && field) {
                // console.log(key, this.model.fields[key].label)
                if (field.getLabel) {
                    return field.getLabel(this.data)
                }
                return field.label
            }
            return null
        },
        getValue(key) {
            let value = this.data[key]

            // check for child property - e.g. 'retrofit_manufacturer.name'
            // (requires adding a read-only field with the same name to the field model)
            const dot = key.indexOf('.')
            if (dot !== -1) {
                const table = key.substring(0, dot)
                const attr = key.substring(dot + 1)
                value = this.data[table] ? this.data[table][attr] : ''
                return value
            } else if (this.model && this.model.fields[key]) {
                switch (this.model.fields[key].type) {
                    case 'tel':
                        if (value) {
                            value = `<a href="tel:${value}">${value}</a>`
                        }
                        break
                    case 'email':
                        if (value) {
                            value = `<a href="mailto:${value}">${value}</a>`
                        }
                        break
                    case 'check':
                        value = value ? '&#10004;' : null
                        break
                    case 'radios':
                        value = this.getOptionText(key, value)
                        break
                    case 'select':
                        value = this.getOptionText(key, value)
                        break
                    case 'date':
                        value = this.getDateDisplay(key, value)
                        break
                }
            }
            return value
        },
        getOptionText(key, value) {
            const opts = this.model.fields[key].options
            if (opts) {
                const opt = _.find(opts, (opt) => {
                    return opt.value === value
                })
                return opt ? opt.text : ''
            }
            return ''
        },
        getDateDisplay(key, value) {
            const m = moment.utc(value)
            if (m.isValid()) {
                return m.local().format('MMM D \'YY h:mm A')
            } else {
                return ''
            }
        },
        // returns a dynamic CSS expression (e.g. {key-value__emission_tier: true})
        // that can be used (in the parent component) to apply custom styling the value of a specific field
        getValueCssClass(key) {
            const keyClass = `field-value__${key}`
            const expr = {}
            expr[keyClass] = true
            return expr
        }
    },
})
</script>
