<template>
    <div class="carbohydrate-thinning-details">
        <breadcrumb>
            <router-link :to="{name: 'locationDetails', params: {location_id: location_id}}">
                Location
            </router-link>
        </breadcrumb>
        <about-modal topic="carbohydrate" class="mb-3"/>
        <heading title="Carbohydrate Thinning Model"/>

        <spinner-until :condition="loaded">
            <b-row>
                <b-col>
                    <h2>Location</h2>
                    {{ locationTitle }}
                    <div class="my-3" style="max-width:200px;">
                        <label>Select Date:</label>
                        <b-form-input type="date" v-model="endDate"/>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <highcharts :options="carbohydrate.chart" class="chart mb-5"></highcharts>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table striped bordered responsive
                             :items="items"
                             :fields="fields"
                             label-sort-asc=""
                             label-sort-desc=""
                             label-sort-clear="">
                        <template #head()="data">
                            <span v-html="data.label"></span>
                        </template>

                        <template v-slot:cell()="row">
                            <div v-if="row.field.key === 'date'">
                                {{ formatDate(row.item[row.field.key]) }}
                            </div>

                            <div v-else>
                                {{ row.item[row.field.key] }}
                            </div>
                        </template>
                    </b-table>
                    <div class="column-toggle mb-5">
                        <h3>Show Columns:</h3>
                        <div class="column-toggle__checkboxes">
                            <b-checkbox v-for="field in fields" :key="field.key" v-model="field.isChecked"
                                        @input="toggleColumn($event, field)">
                                <span v-html="field.label"></span>
                            </b-checkbox>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </spinner-until>
    </div>
</template>

<script>

import AboutModal from "@/components/AboutModal.vue";
import {Carbohydrate} from "@/providers/carbohydrate";
import SpinnerUntil from "@/components/SpinnerUntil.vue";
import Heading from "@/components/Heading.vue";
import Utils from "@/providers/Utils";

export default {
    name: "CarbohydrateThinningDetails",
    components: {Heading, SpinnerUntil, AboutModal},
    props: {
        location_id: String,
    },
    data() {
        return {
            location: {},
            items: [],
            carbohydrate: new Carbohydrate(),
            fields: [{
                key: 'date',
                label: 'Date',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'maxt',
                label: 'Max Temp (&deg;F)',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'mint',
                label: 'Min Temp (&deg;F)',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'srad',
                label: 'Solar Rad (MJ/m&#178;)',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'thinIndex',
                label: 'Daily Balance (g/day)',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'avg7day',
                label: 'Weighted 7-Day Avg Balance (g/day)',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'dd4cAccum',
                label: 'Accum. DD since bloom',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'efficacy',
                label: 'Expected Thinning Efficacy',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }, {
                key: 'recommend',
                label: 'Thinning Recommendation',
                sortable: true,
                isChecked: true,
                thClass: '',
                tdClass: ''
            }],
        }
    },
    async mounted() {
        this.location = await this.$api.getLocation(this.location_id)
        await this.carbohydrate.fetchResults(this.location)
        this.items = this.carbohydrate.newaDataRowsForTable
    },
    computed: {
        loaded() {
            return this.carbohydrate.dataHasLoaded
        },
        locationTitle() {
            return `${this.location.farm} - ${this.location.block} - ${this.location.variety} (${this.location.year})`
        },
        // format endDate for date input; refresh model on change
        endDate: {
            get() {
                return this.carbohydrate.endDate.split('T')[0]
            },
            set(val) {
                this.carbohydrate.endDate = new Date(val).toISOString()
                this.carbohydrate.fetchResults(this.location)
            },
        }
    },
    methods: {
        toggleColumn(event, field) {
            field.thClass = field.tdClass = event ? '' : 'd-none'
        },
        formatDate(d) {
            return Utils.formatDate(d)
        }
    }
}

</script>

<style scoped>

</style>