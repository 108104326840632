<template>
    <div class="fruit-growth-rate-details">
        <breadcrumb>
            <router-link :to="{name: 'locationDetails', params: {location_id: location_id}}">
                Location
            </router-link>
        </breadcrumb>
        <about-modal class="mb-3" topic="thinning"/>
        <heading title="Fruit Growth Rate Model"/>

        <spinner-until :condition="loaded">
            <b-row>
                <b-col class="block-buttons col-12 col-lg-3">
                    <h2>Location</h2>
                    <strong>{{ locationTitle }}</strong>
                    <br>
                    <br>
                    Avg. Flower Clusters Counted per Tree:
                    <mark>{{ clusters_counted_per_tree }}</mark>
                    <br>
                    Potential Fruit per Tree:
                    <mark>{{ potential_fruit_per_tree }}</mark>
                    <br>
                    Target Fruit per Tree:
                    <mark>{{ target_fruit_per_tree }}</mark>
                    <thinning-dataset-edit-modal :location_id="location_id" :model-instance="thinning.dataset"
                                                 class="my-2">Edit Details
                    </thinning-dataset-edit-modal>

                    <router-link class="btn btn-info mb-4 btn-icon"
                                 :to="{name: 'thinningDatasetSummary', params: {location_id: location_id}}">View
                        Model Data
                    </router-link>
                </b-col>
                <b-col>
                    <b-row>
                        <h2>Measurements</h2>
                        <data-table-local-sort :items="thinning_measurements"
                                               :fields="thinning_measurement_fields">
                            <template v-slot:customField="slotProps">
                                <div v-if="slotProps.row.field.key === 'actions'">
                                    <router-link class="btn btn-info mb-2 me-2 btn-icon"
                                                 :to="{name: 'thinningMeasurementDetails', params: {location_id: location_id, measurement_id: slotProps.row.item.id}}">
                                        <b-icon icon="pencil"/>
                                    </router-link>
                                    <b-button class="btn-info mb-2"
                                              @click="deleteThinningMeasurement(slotProps.row.item)">
                                        <b-icon icon="dash-circle"/>
                                    </b-button>
                                </div>
                                <div v-else-if="slotProps.row.field.key == 'created'">
                                    {{ formatDate(slotProps.row.item[slotProps.row.field.key]) }}
                                </div>
                                <div v-else>
                                    {{ slotProps.row.item[slotProps.row.field.key] ? slotProps.row.item[slotProps.row.field.key].toFixed(2) : '' }}
                                </div>
                            </template>
                        </data-table-local-sort>
                    </b-row>
                    <b-row>
                        <b-col>
                            <router-link
                                :to="{name: 'thinningMeasurementDetails', params: {location_id: location_id, measurement_id: newMeasurementId, is_new: true}}"
                                class="btn btn-primary me-3"
                                :class="{'disabled': !canCreateMeasurement}">
                                <b-icon icon="plus-circle-fill"/>
                            </router-link>
                            <router-link :to="{name: 'exportMeasurementData', params: {location_id: location_id}}"
                                         class="btn btn-primary me-3">
                                <b-icon icon="download"/>
                                Export
                            </router-link>
                            <router-link :to="{name: 'importMeasurementData', params: {location_id: location_id}}"
                                         class="btn btn-primary">
                                <b-icon icon="upload"/>
                                Import
                            </router-link>
                        </b-col>
                    </b-row>
                    <b-row class="mb-5">
                        <b-col>
                            <b-button @click="refreshAll" class="my-3">Recalculate</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col>
                    <highcharts :options="thinning.chart" class="chart"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h2>Spray Records</h2>
                    <mark>Spray dates are indicated by gray vertical lines on the graph above.</mark>
                    <data-table-local-sort
                        :items="spray_records"
                        :fields="spray_record_fields">
                        <template v-slot:customField="slotProps">
                            <div v-if="slotProps.row.field.key === 'stage'" class="d-flex">
                                {{ fieldModel.fields.stage.options[slotProps.row.item.stage] }}
                            </div>
                            <div v-else-if="slotProps.row.field.key === 'actions'" class="d-flex">
                                <spray-record-edit-modal :location_id="location_id" :model-instance="slotProps.row.item"
                                                         class="me-2">
                                    <b-icon icon="pencil" />
                                </spray-record-edit-modal>
                                <b-button @click="deleteSprayRecord(slotProps.row.item)">
                                    <b-icon icon="dash-circle" />
                                </b-button>
                            </div>
                            <div v-else-if="slotProps.row.field.key === 'date'">
                                {{ formatDate(slotProps.row.item[slotProps.row.field.key]) }}
                            </div>
                            <div v-else>
                                {{ slotProps.row.item[slotProps.row.field.key] }}
                            </div>
                        </template>
                    </data-table-local-sort>
                </b-col>
            </b-row>

        </spinner-until>
    </div>
</template>

<script>
import DataTableLocalSort from "@/components/DataTableLocalSort.vue"
import Breadcrumb from "@/components/Breadcrumb.vue"
import AboutModal from "@/components/AboutModal.vue"
import {Thinning} from "@/providers/thinning"
import Utils from '@/providers/Utils'
import SpinnerUntil from "@/components/SpinnerUntil.vue"
import Heading from "@/components/Heading.vue"
import SprayRecordEditModal from "@/components/SprayRecordEditModal.vue";
import SprayRecordFields from "@/models/sprayRecordFields";
import moment from "moment"

export default {
    name: "FruitGrowthRateDetails",
    components: {SprayRecordEditModal, Heading, SpinnerUntil, AboutModal, DataTableLocalSort},
    props: {
        location_id: String,
    },
    data() {
        return {
            location: {},
            spray_records: [],
            thinning: new Thinning(),
            fieldModel: SprayRecordFields,

            thinning_measurement_fields: [
                {
                    key: 'created',
                    label: 'Date',
                },
                {
                    key: 'mean_of_over_50_pct_fastest',
                    label: 'Average Size',
                },
                {
                    key: 'actions',
                    label: 'Actions',
                }],
            spray_record_fields: [
                {
                    key: 'date',
                    label: 'Date',
                },
                {
                    key: 'stage',
                    label: 'Stage',
                },
                {
                    key: 'chemical',
                    label: 'Chemical',
                }],
        }
    },
    async mounted() {
        this.location = await this.$api.getLocation(this.location_id)
        const dataset = await this.$api.getThinningDataset(this.location_id)
        this.spray_records = await this.$api.getSprayRecords(this.location_id)
        await this.thinning.setDataset(dataset, this.location, this.spray_records)
    },
    computed: {
        loaded() {
            return !!this.thinning.measurements
        },
        locationTitle() {
            return `${this.location.farm} - ${this.location.block} - ${this.location.variety} (${this.location.year})`
        },
        target_fruit_per_tree() {
            return this.thinning.dataset?.target_fruit_per_tree
        },
        clusters_counted_per_tree() {
            return this.thinning.dataset?.clusters_counted_per_tree
        },
        potential_fruit_per_tree() {
            return this.thinning.dataset?.potential_fruit_per_tree
        },
        // used to get summary info if exists
        thinning_measurements() {
            if (this.loaded) {
                return this.thinning.measurements.map(meas => ({
                    id: meas.id,
                    created: meas.created,
                    mean_of_over_50_pct_fastest: meas.summary.mean_of_over_50_pct_fastest
                }))
            }
            return []
        },
        newMeasurementId() {
            return moment().format('YYYY-MM-DD')
        },
        // Hasn't created a measurement today and has completed Fruit Growth Rate Model
        canCreateMeasurement() {
            if (this.loaded) {
                const hasCreatedToday = this.thinning.measurements.some(meas => meas.id === moment().format('YYYY-MM-DD'))
                const hasThinningDataset = this.thinning.dataset
                return !hasCreatedToday && hasThinningDataset
            }
            return false
        }
    },
    methods: {
        formatDate(d) {
            return Utils.formatDate(d)
        },
        async deleteThinningMeasurement(measurement) {
            this.$bvModal.msgBoxConfirm('Permanently delete this Measurement?', {
                title: 'Confirm deletion',
                okTitle: 'Delete'
            }).then(async (value) => {
                if (value) {
                    await this.$api.deleteThinningMeasurement(this.location_id, measurement)
                    this.$toasted.info('Measurement deleted')
                    this.$router.go(0)
                }
            })
        },
        refreshAll() {
            this.thinning.measurements.forEach(meas => this.thinning.refreshThinningMeasurementSummary(meas))
            this.thinning.refreshThinningChartData('refresh')
            this.$toasted.info('Measurement summaries refreshed')
        }
    }
}
</script>

<style lang="scss">

</style>