<template>
    <div class="irrigation-details">
        <breadcrumb>
            <router-link :to="{name: 'locationDetails', params: {location_id: location_id}}">
                Location
            </router-link>
        </breadcrumb>
        <about-modal class="mb-3" topic="irrigation" />
        <heading title="Irrigation Model" />

        <spinner-until :condition="loaded">

            <b-row>
                <b-col md="4">
                    <h2>Location</h2>
                    <h5>{{ locationTitle }}</h5>
                    <label>Select Date:</label>
                    <b-form-input type="date" v-model="endDate" />

                    <router-link class="btn btn-info d-block my-2 btn-icon"
                                 :to="{name: 'irrigationRecords', params: {location_id: location_id}}">Go To Irrigation
                        Records
                    </router-link>
                    <b-button class="d-block w-100" @click="saveData">Save Entered Data</b-button>
                </b-col>
            </b-row>
            <b-row class="py-4">
                <b-table class="mb-4" striped bordered responsive sticky-header="400px"
                         :items="items"
                         :fields="fields"
                         label-sort-asc=""
                         label-sort-desc=""
                         label-sort-clear="">
                    <template #head()="data">
                        <span v-html="data.label"></span>
                    </template>

                    <template #cell(rainfall_in)="data">
                        <b-input v-model="data.item.rainfall_in" @change="data.item.rainfall_edited = true" :key="`rainfall_in-${data.item.id}`" type="number" :number="true "/>
                    </template>

                    <template #cell(irrigation)="data">
                        <b-input v-model="data.item.irrigation" @change="data.item.irrigation_edited = true" :key="`irrigation-${data.item.id}`" type="number" :number="true" />
                    </template>

                    <template v-slot:cell()="row">
                        <div v-if="row.field.key === 'date'">
                            {{ formatDate(row.item[row.field.key]) }}
                        </div>

                        <div v-else>
                            {{ !isNaN(row.item[row.field.key]) ? row.item[row.field.key].toFixed(2) : row.item[row.field.key] }}
                        </div>
                    </template>
                </b-table>
                <div class="column-toggle">
                    <h4>Show Columns:</h4>
                    <div class="column-toggle__checkboxes">
                        <b-checkbox v-for="field in fields" :key="field.key" v-model="field.isChecked"
                                    @input="toggleColumn($event, field)">
                            <span v-html="field.label"></span>
                        </b-checkbox>
                    </div>
                </div>
            </b-row>
            <b-row>
                <highcharts :options="irrigation.chart" class="chart"></highcharts>
            </b-row>

        </spinner-until>
    </div>
</template>

<script>
import AboutModal from "@/components/AboutModal.vue";
import {Irrigation} from "@/providers/irrigation";
import SpinnerUntil from "@/components/SpinnerUntil.vue";
import Heading from "@/components/Heading.vue";
import Utils from "@/providers/Utils";

export default {
    name: "IrrigationDetails",
    components: {Heading, SpinnerUntil, AboutModal},
    props: {
        location_id: String,
    },
    data() {
        return {
            location: {},
            items: [],
            irrigation: new Irrigation(),
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'water_balance_daily',
                    label: 'Water Balance Daily',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'water_balance_cumulative',
                    label: 'Water Balance Cumulative',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'orchard_et_per_tree',
                    label: 'Orchard ET (per tree)',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'orchard_et_gpa',
                    label: 'Orchard ET (gal/acre)',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'orchard_et_cumulative',
                    label: 'Cumulative ET',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'rainfall_in',
                    label: 'Rainfall (in)',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'rainfall_gpa',
                    label: 'Rainfall (gal/acre)',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'rainfall_cumulative',
                    label: 'Cumulative Rainfall',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'irrigation',
                    label: 'Irrigation (gal/acre)',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'irrigation_cumulative',
                    label: 'Cumulative Irrigation',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'irrigation_time',
                    label: 'Irrigation Time',
                    sortable: true,
                    isChecked: false,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'irrigation_recommendation',
                    label: 'Irrigation Recommendation',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                }
            ]
        }
    },
    async mounted() {
        this.location = await this.$api.getLocation(this.location_id)
        await this.irrigation.setLocation(this.location)
        await this.irrigation.fetchResults()
        this.items = this.irrigation.resultRows
    },
    computed: {
        loaded() {
            return !!this.location
        },
        locationTitle() {
            return `${this.location.farm} - ${this.location.block} - ${this.location.variety} (${this.location.year})`
        },
        // format endDate for date input; refresh model on change
        endDate: {
            get() {
                return this.irrigation.endDate.split('T')[0]
            },
            set(val) {
                this.irrigation.endDate = new Date(val).toISOString()
                this.irrigation.fetchResults(this.location)
            },
        }
    },
    methods: {
        toggleColumn(event, field) {
            field.thClass = field.tdClass = event ? '' : 'd-none'
        },
        columnStatus(field) {
            return !!field.thClass
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
        // For updating editable irrigation or rainfall_in fields
        saveData() {
            console.log(this.items)
            this.irrigation.updateIrrigationRecords()
            this.$toasted.success('Irrigation values saved')
        }
    },

}
</script>

<style scoped>

</style>