import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../pages/HomeView.vue'
import SupportView from "@/pages/SupportView.vue"
import UserSignup from "@/pages/UserSignup.vue"
import UserLogin from "@/pages/UserLogin.vue"
import UserResetPassword from "@/pages/UserResetPassword.vue"
import LocationList from "@/pages/LocationList.vue"
import LocationDetails from "@/pages/LocationDetails.vue"
import SprayRecordList from "@/pages/SprayRecordList.vue"
import FruitGrowthRateDetails from "@/pages/FruitGrowthRateDetails.vue";
import IrrigationDetails from "@/pages/IrrigationDetails.vue";
import IrrigationRecordList from "@/pages/IrrigationRecordList.vue";
import CarbohydrateThinningDetails from "@/pages/CarbohydrateThinningDetails.vue";
import ThinningDatasetSummary from "@/pages/ThinningDatasetSummary.vue";
import ExportMeasurementData from "@/pages/ExportMeasurementData.vue";
import ImportMeasurementData from "@/pages/ImportMeasurementData.vue";
import ThinningMeasurementDetails from "@/pages/ThinningMeasurementDetails.vue";
import AccountView from "@/pages/AccountView.vue";

Vue.use(VueRouter)

const router = new VueRouter(
    {
      mode: 'history',
      routes: [
        {
          path: '/',
          name: 'home',
          component: HomeView
        },
        {
          path: '/account',
          name: 'account',
          component: AccountView
        },
        {
          path: '/support',
          name: 'support',
          component: SupportView
        },
        {
          path: '/signup',
          name: 'signup',
          component: UserSignup
        },
        {
          path: '/login',
          name: 'login',
          component: UserLogin
        },
        {
          path: '/resetPassword',
          name: 'resetPassword',
          component: UserResetPassword
        },
        {
          path: '/locations',
          name: 'locations',
          component: LocationList
        },
        {
          path: '/location/:location_id',
          name: 'locationDetails',
          component: LocationDetails,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/spray_records',
          name: 'sprayRecords',
          component: SprayRecordList,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/fruit_growth_rate_details',
          name: 'fruitGrowthRateDetails',
          component: FruitGrowthRateDetails,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/thinning_measurement_details/:measurement_id/?:is_new',
          name: 'thinningMeasurementDetails',
          component: ThinningMeasurementDetails,
          props: (route) => ({
            location_id: route.params.location_id,
            measurement_id: route.params.measurement_id,
            is_new: route.params.is_new
          })
        },
        {
          path: '/location/:location_id/export_measurement_data',
          name: 'exportMeasurementData',
          component: ExportMeasurementData,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/import_measurement_data',
          name: 'importMeasurementData',
          component: ImportMeasurementData,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/thinning_dataset_summary',
          name: 'thinningDatasetSummary',
          component: ThinningDatasetSummary,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/irrigation_details',
          name: 'irrigationDetails',
          component: IrrigationDetails,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/irrigation_records',
          name: 'irrigationRecords',
          component: IrrigationRecordList,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
        {
          path: '/location/:location_id/carbohydrate_thinning_details',
          name: 'carbohydrateThinningDetails',
          component: CarbohydrateThinningDetails,
          props: (route) => ({
            location_id: route.params.location_id,
          })
        },
      ]
    }
)

export default router
