<template>
    <div class="user-login">
        <spinner-until :condition="!loading">
            <data-form :field-groups="fieldGroups"
                       :field-model="userModel"
                       save-button-label="Log in"
                       :top-submit-button="false"
                       :bottom-submit-button="true"
                       :on-submit-handler="login"
                       title="Log in"/>

            <div class="clearfix"/>

            <div>Don't have an account yet? Create new account
                <router-link style="display: inline" to="/signup">here</router-link>
            </div>

            <div>Forgot your password? Reset it
                <router-link style="display: inline" to="/resetPassword">here</router-link>
            </div>
        </spinner-until>
    </div>
</template>

<script>

import UserFields from "@/models/User"
import SpinnerUntil from "@/components/SpinnerUntil";

export default {
    name: 'UserLogin',
    components: {SpinnerUntil},
    data() {
        return {
            userModel: UserFields,
            fieldGroups: [
                ['email', 'password'],
            ],
            loading: false
        }
    },
    computed: {
        email() {
            return this.$store.state.currentUser.email
        }
    },
    methods: {
        async login(modelInstance) {
            if (this.email) {
                this.$toasted.success('Already Logged in')
            } else {
                this.loading = true
                await this.$api.loginUser(this, modelInstance.email, modelInstance.password)
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
</style>
