<template>
    <div class="weather-station-map">
        <b-button @click="show=true">Select...</b-button>
        <div v-show="show">
            <b-select v-model="station" :options="stationOptions" @input="stationSelectedFromList" />
            <b-select v-model="state" :options="stateOptions" @input="stateSelected" />
            <gmap-map ref="mapRef"
                      :center="center"
                      :zoom="zoom"
                      :style="{width:'400px',height:'400px'}"
                      :options="options" />
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import {gmapApi} from 'vue2-google-maps'

export default {
    name: "WeatherStationMap",
    components: {},
    props: {},
    data() {
        return {
            center: {lat: 42.9543, lng: -75.5262},
            zoom: 6,
            options: {gestureHandling: 'greedy', disableDefaultUI: true},

            stateCenters: [],
            state: null,
            stations: [],
            station: null,
            allOverlays: [],
            filteredOverlays: [],
            map: null,

            stationOptions: [],
            stateOptions: [],

            show: false
        }
    },
    computed: {
        google: gmapApi,
    },
    async mounted() {
        if (this.$refs.mapRef.$mapPromise) {
            this.map = await this.$refs.mapRef.$mapPromise
            await this.loadMarkers()
        }

        this.initStates()

        // register a global function that will call the stationSelected method in this component
        // when a button in the infoWindow is clicked
        window['stationSelected'] = this.stationSelected
    },
    watch: {
        filteredOverlays: function (newVal, oldVal) {
            oldVal.forEach(m => m.setMap(null))
            newVal.forEach(m => m.setMap(this.map))
        }
    },
    methods: {
        // change state filter
        stateSelected() {
            const stateCenter = _.find(this.stateCenters, {id: this.state.id})
            if (!this.state || this.state.id === 'ALL') {
                this.filteredOverlays = this.allOverlays
            } else {
                this.filteredOverlays = _.filter(this.allOverlays, {state: stateCenter.id})
            }

            if (stateCenter && this.map) {
                this.map.setCenter(new this.google.maps.LatLng(stateCenter.lat, stateCenter.lon))
                this.map.setZoom(stateCenter.zoom)
            }
        },

        // called when a station is selected from the drop-down list
        stationSelectedFromList() {
            this.show = false
            this.$emit('input', this.station)
        },

        // called when a station is selected from the map - value format is 'id|title'
        stationSelected(value) {
            const parts = _.split(value, '|')
            this.show = false
            this.$emit('input', {id: parts[0], title: parts[1]})
        },

        // load list of stations and generate a map marker for each
        async loadMarkers() {
            let url = `https://newa.nrcc.cornell.edu/newaUtil/stateStationList2/goodsr`

            const res = await fetch(url)
            this.stations = (await res.json()).stations

            // TODO: filter these options based on the State select
            this.stationOptions = this.stations.map(s => ({
                value: {id: s.id, title: s.name},
                text: s.name
            }))
            this.stationOptions.unshift({value: null, text: 'Select Station'})

            this.allOverlays = this.stations.map(s => {
                const marker = new this.google.maps.Marker({
                    position: {lat: s.lat, lng: s.lon},
                    title: s.name,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                    id: s.id,
                    elev: s.elev,
                    network: s.network,
                    state: s.state,
                    map: this.map
                })
                marker.addListener('click', () => this.handleOverlayClick(marker))
                return marker
            })

            this.filteredOverlays = this.allOverlays.slice(0) //clone array
        },

        // display an InfoWindow for the selected marker, with a button to select the station
        handleOverlayClick(m) {
            new this.google.maps.InfoWindow({
                content: `<strong>${m.title}</strong>
                <br/>State: ${m.state}
                <br/>Network: ${m.network}
                <br/>Elevation: ${m.elev} ft
                <br/><button type="button" onclick="window.stationSelected('${m.id}|${m.title}')">Select this Station</button>`
            }).open(this.map, m)
        },

        initStates() {
            this.stateCenters = [
                {id: 'AL', lat: 32.6174, lon: -86.6795, zoom: 7, name: 'Alabama'},
                {id: 'CT', lat: 41.6220, lon: -72.7272, zoom: 8, name: 'Connecticut'},
                {id: 'DE', lat: 38.9895, lon: -75.5051, zoom: 8, name: 'Delaware'},
                {id: 'DC', lat: 38.9101, lon: -77.0147, zoom: 8, name: 'DC'},
                {id: 'IL', lat: 40.0411, lon: -89.1965, zoom: 6, name: 'Illinois'},
                {id: 'ID', lat: 44.0682, lon: -114.742, zoom: 6, name: 'Idaho'},
                {id: 'IA', lat: 42.0753, lon: -93.4959, zoom: 6, name: 'Iowa'},
                {id: 'KY', lat: 37.5347, lon: -85.3021, zoom: 6, name: 'Kentucky'},
                {id: 'ME', lat: 45.3702, lon: -69.2438, zoom: 7, name: 'Maine'}, //no stations
                {id: 'MD', lat: 39.0550, lon: -76.7909, zoom: 7, name: 'Maryland'},
                {id: 'MA', lat: 42.2596, lon: -71.8083, zoom: 7, name: 'Massachusetts'},
                {id: 'MI', lat: 44.3461, lon: -85.4114, zoom: 6, name: 'Michigan'},
                {id: 'MN', lat: 46.2810, lon: -94.3046, zoom: 6, name: 'Minnesota'},
                {id: 'MO', lat: 38.3568, lon: -92.4571, zoom: 6, name: 'Missouri'},
                {id: 'NE', lat: 41.5392, lon: -99.7968, zoom: 6, name: 'Nebraska'},
                {id: 'NH', lat: 43.6805, lon: -71.5818, zoom: 7, name: 'New Hampshire'},
                {id: 'NJ', lat: 40.1907, lon: -74.6733, zoom: 7, name: 'New Jersey'},
                {id: 'NY', lat: 42.9543, lon: -75.5262, zoom: 6, name: 'New York'},
                {id: 'NC', lat: 35.5579, lon: -79.3856, zoom: 6, name: 'North Carolina'},
                {id: 'OH', lat: 40.1905, lon: -82.6707, zoom: 7, name: 'Ohio'},
                {id: 'PA', lat: 40.8786, lon: -77.7985, zoom: 7, name: 'Pennsylvania'},
                {id: 'RI', lat: 41.6762, lon: -71.5562, zoom: 9, name: 'Rhode Island'},
                {id: 'SC', lat: 33.6290, lon: -80.9500, zoom: 6, name: 'South Carolina'},
                {id: 'SD', lat: 43.9169, lon: -100.2282, zoom: 6, name: 'South Dakota'},
                {id: 'UT', lat: 39.4192, lon: -111.9506, zoom: 7, name: 'Utah'},
                {id: 'VT', lat: 44.0688, lon: -72.6663, zoom: 7, name: 'Vermont'},
                {id: 'VA', lat: 37.5229, lon: -78.8531, zoom: 7, name: 'Virginia'},
                {id: 'WV', lat: 38.6409, lon: -80.6230, zoom: 7, name: 'West Virginia'},
                {id: 'WI', lat: 44.6243, lon: -89.9941, zoom: 6, name: 'Wisconsin'},
                {id: 'ALL', lat: 42.5000, lon: -75.7000, zoom: 6, name: 'All States'},
            ]
            this.stateOptions = this.stateCenters.map(c => ({
                value: c,
                text: c.name
            }))
            this.stateOptions.unshift({value: null, text: 'Filter by State'})
        }
    },
}
</script>

<style scoped>

</style>