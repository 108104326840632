import {ChartColors} from "./chart-colors";

import * as _ from 'lodash';
import moment from 'moment';
import {ThinningMeasurementSummary} from "@/models/thinning-measurement-summary";
import Api from '@/providers/Api'

/**
 * Service for misc. calculations in the Thinning Model (AKA Fruit Growth Rate Model)
 */
export class Thinning {

    public dataset: any;
    public location: any;
    public sprayRecords: any[];
    public measurements: any[];

    public chart: any;
    chartUpdate: boolean = false;

    constructor() {
        this.initCharts();
    }

    async setDataset(dataset, location, sprayRecords = null) {
        this.dataset = dataset
        this.location = location

        this.sprayRecords = sprayRecords
        this.measurements = await Api.getThinningMeasurements(location.id)
        this.refreshThinningChartData()
    }

    getFirstMeasurement() {
        const dates = _.sortBy(_.map(this.measurements, 'created'));
        if (dates.length > 0) {
            return _.find(this.measurements, ['created', dates[0]]);
        } else {
            return null;
        }
    }

    getPreviousMeasurement(meas, decrement = 1) {
        const dates = _.sortBy(_.map(this.measurements, 'created'));
        const index = _.indexOf(dates, meas.created);

        if (index >= decrement) {
            return _.find(this.measurements, ['created', dates[index - decrement]]);
        } else {
            return null;
        }
    }

    getNextMeasurement(meas) {
        const dates = _.sortBy(_.map(this.measurements, 'created'));
        const index = _.indexOf(dates, meas.created);
        if (index < dates.length - 1) {
            return _.find(this.measurements, ['created', dates[index + 1]]);
        } else {
            return null;
        }
    }

    getDaysSinceLast(meas) {
        const prev = this.getPreviousMeasurement(meas);
        if (prev) {
            const prevCreated = moment(prev.created).startOf('day');
            const currentCreated = moment(meas.created).startOf('day');
            return currentCreated.diff(prevCreated, "days");
        } else {
            return null;
        }
    }

    static getNonZeroValues(meas) {
        if (!meas.data) {
            return [];
        } else {
            return _.compact(_.map(_.values(meas.data), function (v) {
                return _.toNumber(v);
            }));
        }
    }

    static getNonZeroAndNonOutliers(meas) {
        if (!meas.data) {
            return [];
        } else {
            return _.compact(_.map(_.filter(meas.data, function (value, key) {
                return !meas.data_analysis[key + '_outlier'] &&
                    !meas.data_analysis[key + '_growth_rate_excessive'];
            }), function (v) {
                return _.toNumber(v);
            }));
        }
    }

    static getNonFlaggedDiameterChanges(meas) {
        // console.log('DATA ANALYSIS', meas.data_analysis)
        if (!meas.data) {
            return [];
        } else {
            return _.compact(_.map(_.filter(_.keys(meas.data), function (key) {
                return !meas.data_analysis[key + '_outlier'] &&
                    !meas.data_analysis[key + '_growth_rate_excessive'];
            }), function (key) {
                return meas.data_analysis[key + '_change'];
            }))
        }
    }

    getNonZeroValueCount(meas, t, c) {
        return _.compact(
            _.values(
                _.filter(meas.data, function (value, index) {
                    return _.startsWith(index, t + '_' + c + '_');
                })
            )
        ).length;
    }

    hasOnlyAZeroValue(meas, t, c) {
        const vals = _.values(
            _.filter(meas.data, function (value, index) {
                return _.startsWith(index, t + '_' + c + '_') && value !== ""; // NB: exclude erased values ("")
            })
        );
        return vals.length == 1 && (vals[0] == 0 || vals[0] == '0');
    }

    // returns the position of the value for the given key in the
    // sort (biggest first) of values for the same cluster
    getSortRankInCluster(meas, key) {
        const tcf = key.split('_');
        const sortedValues = _.reverse(
            _.sortBy(
                _.map(
                    _.filter(meas.data, (val, valKey) => {
                        return _.startsWith(valKey, tcf[0] + '_' + tcf[1] + '_');
                    }),
                    (dval) => _.toNumber(dval)
                )
            )
        );
        return sortedValues.indexOf(_.toNumber(meas.data[key]));
    }

    getValueForNthFruitInCluster(meas, key, n) {
        const tcf = key.split('_');
        const sortedValues = _.reverse(
            _.sortBy(
                _.map(
                    _.filter(meas.data, (val, valKey) => {
                        return _.startsWith(valKey, tcf[0] + '_' + tcf[1] + '_');
                    }),
                    (dval) => _.toNumber(dval)
                )
            )
        );
        if (sortedValues.length > n) {
            return sortedValues[n];
        } else {
            return null;
        }
    }

    measurementHasDataForKey(meas, key) {
        if (!meas) {
            return true; // not really, but allow input of all data for first measurement
        } else {
            const data = meas.data[key];
            return data != null && data != 0 && data != '0' && data != 0.1 && data != '0.1';
        }
    }

    measurementHasDataForFlower(meas, t, c, f) {
        return this.measurementHasDataForKey(meas, t + '_' + c + '_' + f);
    }

    // TODO: fix (relies on old assumptions about presence of measurement keys)
    // measurementHasDataForCluster(meas, t, c) {
    //     return _.find(_.keys(meas.data), (key) => {
    //         return key.indexOf(t + '_' + c) === 0;
    //     }) != null;
    // }

    // TODO: fix (relies on old assumptions about presence of measurement keys)
    // measurementHasDataForAllClusters(meas, t, cluster_count) {
    //     for (let i = 1; i <= cluster_count; i++) {
    //         const clusterHasData = _.find(_.keys(meas.data), (key) => {
    //             return key.indexOf(t + '_') === 0;
    //         }) != null;
    //         if (!clusterHasData) {
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    refreshThinningMeasurementSummary(meas) {

        console.log('refreshThinningMeasurementSummary: ' + meas.created);

        let s = {} as ThinningMeasurementSummary;
        const prev = this.getPreviousMeasurement(meas);

        const daysSinceLast = this.getDaysSinceLast(meas);
        s.days_since_last = daysSinceLast;

        // calculate diameter changes (use same keys as for measurement data),
        // and flag excessive growth rates
        const self = this;
        const diameterChanges = {};
        if (!meas.data_analysis) {
            meas.data_analysis = {};
        }

        _.each(_.keys(meas.data), (key) => {
            diameterChanges[key] = 0; // default
            if (prev) {
                // NB: compare each fruitlet with the corresponding one in the previous measurement
                // when ranked by size (e.g. fruitlet #3 will typically NOT be compared to the previous #3,
                // unless they both have the same size rank)
                const order = this.getSortRankInCluster(meas, key);
                const prevVal = this.getValueForNthFruitInCluster(prev, key, order);

                if (prevVal && this.measurementHasDataForKey(meas, key)) {
                    diameterChanges[key] = meas.data[key] - prevVal;

                    const growthRate = diameterChanges[key] / daysSinceLast;

                    meas.data_analysis[key + '_previous'] = prevVal;
                    meas.data_analysis[key + '_change'] = diameterChanges[key];

                    meas.data_analysis[key + '_growth_rate'] = growthRate;

                    //Normally growth rate should always be positive, but if the current entry is empty
                    //Then growth rate will be calculated as negative. Also flag this edge case
                    if (Math.abs(growthRate) > 1.5) {
                        meas.data_analysis[key + '_growth_rate_excessive'] = true;
                    } else {
                        meas.data_analysis[key + '_growth_rate_excessive'] = false;
                    }
                } else {
                    meas.data_analysis[key + '_previous'] = null;
                    meas.data_analysis[key + '_growth_rate'] = null;
                    meas.data_analysis[key + '_growth_rate_excessive'] = null;
                }
            }
        });

        // flag outliers
        const mean = _.mean(_.values(diameterChanges));
        const stdDev = this.standardDeviation(_.values(diameterChanges));

        s.std_dev_of_all_by_diameter_growth = stdDev;
        _.each(_.keys(meas.data), (key) => {
            //Outliers live 2 STDs outside the mean
            if (diameterChanges[key] > mean + 2 * stdDev ||
                diameterChanges[key] < mean - 2 * stdDev) {
                meas.data_analysis[key + '_outlier'] = true;
            } else {
                meas.data_analysis[key + '_outlier'] = false;
            }
        })

        const nonFlaggedDiameterChanges = Thinning.getNonFlaggedDiameterChanges(meas)

        const top_15_by_diameter = _.take(
            _.reverse(
                _.sortBy(
                    Thinning.getNonZeroAndNonOutliers(meas)
                )
            ),
            15
        );

        s.mean_of_top_15_by_diameter = this.nullIfNaN(
            _.mean(
                top_15_by_diameter
            )
        );

        s.mean_of_all_by_diameter = this.nullIfNaN(
            _.mean(
                Thinning.getNonZeroAndNonOutliers(meas)
            )
        );

        // mean_of_top_15_by_diameter_growth
        const top_15_by_diameter_growth = _.take(
            _.reverse(
                _.sortBy(
                    nonFlaggedDiameterChanges
                )
            ),
            15
        );
        // console.log('top_15_by_diameter_growth', top_15_by_diameter_growth);

        s.mean_of_top_15_by_diameter_growth = this.nullIfNaN(
            _.mean(
                top_15_by_diameter_growth
            )
        );

        // half_mean_of_top_15_by_diameter_growth
        s.half_mean_of_top_15_by_diameter_growth = s.mean_of_top_15_by_diameter_growth / 2;

        // count_of_measured_fruit
        s.count_of_measured_fruit = _.size(Thinning.getNonZeroAndNonOutliers(meas));

        // count_of_over_50_pct_fastest
        s.count_of_over_50_pct_fastest = _.size(
            _.filter(nonFlaggedDiameterChanges, function (v) {
                return v > s.half_mean_of_top_15_by_diameter_growth;
            })
        );

        // count_of_under_50_pct_fastest
        s.count_of_under_50_pct_fastest = s.count_of_measured_fruit - s.count_of_over_50_pct_fastest;

        // const prev = this.getPreviousMeasurement(meas);
        if (prev) {
            s.mean_of_over_50_pct_fastest = _.mean(
                _.filter(Thinning.getNonZeroAndNonOutliers(meas), function (v) {
                    return v > s.half_mean_of_top_15_by_diameter_growth;
                })
            );
        } else {
            // for first measurement, take mean of all fruit sizes
            s.mean_of_over_50_pct_fastest = _.mean(
                Thinning.getNonZeroAndNonOutliers(meas)
            );
        }

        // predicted_pct_setting
        const first = this.getFirstMeasurement();
        if (first) {
            s.predicted_pct_setting = this.nullIfNaN(
                100 * s.count_of_over_50_pct_fastest / _.size(Thinning.getNonZeroAndNonOutliers(first))
            );
        } else {
            s.predicted_pct_setting = null;
        }

        meas.summary = s;

        // console.log(s);

        Api.updateThinningMeasurement(this.location.id, meas);
    }

    initCharts() {
        this.chart = {
            title: {text: 'Predicted Fruit Setting'},
            chart: {type: 'column'},
            tooltip: {enabled: false},
            yAxis: {
                title: {text: 'Percentage/Number of Fruit'},
                labels: {
                    formatter: function () {
                        // @ts-ignore
                        return this.value;
                    }
                },
                max: 100
            },
            xAxis: [{
                type: 'datetime',
                tickInterval: 4 * 24 * 3600 * 1000, // 4 days
            }],
            legend: {enabled: false},
            plotOptions: {},
            series: [{data: []}]
        };
    }

    refreshThinningChartData() {

        var self = this;

        console.log('refreshThinningChartData');
        // console.log('measurements', this.measurements);
        // console.log('sprayRecords', this.sprayRecords);

        _.each(this.measurements, (meas) => this.refreshThinningMeasurementSummary(meas));

        const dates = _.sortBy(_.map(this.measurements, 'created'));

        const pcts = _.map(dates, (d) => {
            const meas = _.find(this.measurements, ['created', d]);
            if (meas?.summary) {
                return [moment(d).valueOf(), Math.round(meas.summary['predicted_pct_setting'])];
            } else {
                return [];
            }
        });

        // build series for spray dates (excluding bloom spray)
        const sprays = _.map(
            _.filter(this.sprayRecords, (rec) => rec.stage != 'bloom'),
            (rec, key) => {
                return {
                    x: moment(rec.date).valueOf(),
                    y: 1,
                    id: key,
                    name: ' ', // SprayRecord.stageLabels[rec.stage] + '<br/>Spray', // this shows up as 'key' in the dataLabel formatter function (???)
                    className: 'thinning-spray-date-column'
                }
            }
        );

        this.chart.series = [
            {
                name: 'Predicted Setting Fruit',
                colorByPoint: true,
                colors: [ChartColors.darkBlue],
                data: pcts,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        // @ts-ignore
                        const pct = this.y;
                        if (pct == 0) {
                            return '';
                        } else {
                            let count = 0
                            if (self.dataset) {
                                count = Math.round(self.dataset.potential_fruit_per_tree * pct / 100)
                            }
                            return (count ? count + ' fruit' : '');
                        }
                    }
                }
            },
            {
                // HACK: we show spray dates as a second series, as x-axis plot bands don't work here for some reason (???)
                type: 'column',
                data: sprays,
                dataLabels: {
                    enabled: true,
                    verticalAlign: 'top',
                    formatter: function () {
                        // @ts-ignore
                        return this.key;
                    },
                    y: 10,
                }
            }
        ];

        this.chart.yAxis.labels.formatter = function () {
            const pct = this.value
            if (self.dataset) {
                const count = Math.round(self.dataset.potential_fruit_per_tree * pct / 100)
                return (count ? pct + '% (' + count + ')' : pct + '%')
            } else {
                return ''
            }
        };

        // add horizontal plot bands for potential, target setting
        if (this.dataset) {
            const pctTarget = 100 * this.dataset.target_fruit_per_tree / this.dataset.potential_fruit_per_tree;

            this.chart.yAxis.plotBands = [
                {
                    from: Math.max(100 * .75, 100 - 3),
                    to: 100,
                    zIndex: 5,
                    label: {
                        text: this.dataset.potential_fruit_per_tree
                            ? 'Potential: ' + '100% (' + this.dataset.potential_fruit_per_tree + ' fruit per tree)'
                            : '',
                        verticalAlign: 'top',
                        y: 0
                    }
                },
                {
                    from: 0,
                    to: pctTarget,
                    zIndex: 5,
                    label: {
                        text: 'Target: ' + Math.round(pctTarget) + '% (' + this.dataset.target_fruit_per_tree + ' fruit per tree)',
                        verticalAlign: 'top',
                        y: 15
                    }
                }
            ];
        }

        // console.log('thinning chart', this.chart);

        this.chartUpdate = true; // trigger redraw
    }

    nullIfNaN(val) {
        return _.isNaN(val) ? null : val;
    }

    standardDeviation(values) {
        const mean = _.mean(values);
        // console.log(mean)
        const squareDiffs = _.map(values, (val) => {
            return Math.pow(val - mean, 2);
        })
        return Math.sqrt(
            _.mean(squareDiffs)
        );
    }
}



