<template>
    <div class="import-measurement-data">
        <breadcrumb>
            <router-link :to="{name: 'fruitGrowthRateDetails', params: {location_id: location_id}}">
                Fruit Growth Rate Model
            </router-link>
        </breadcrumb>
        <div class="clearfix"/>
        <heading title="Import Measurement Data"/>

        <mark class="import__intro">
            To import measurement data, paste the tab-separated values into the field below, and
            select Import. The data should be in the same format that is generated by the Export
            function - for example:
            <br><br>
            <pre>
Tree  Cluster  Fruitlet  05/27/2017  05/31/2017
1     1        1         8.48        12.11
1     1        2         8.42        11.55
            </pre>
            <strong>NOTE:</strong> Any [E] or [O] annotations must be removed before importing!<br>
        </mark>
        <template v-if="importError">
            <strong>Import Error: </strong>
            {{ importError }}
        </template>
        <textarea v-model="tsvForImport" class="my-3" />
        <br>
        <b-button @click="parseTsv">Import</b-button>
    </div>
</template>

<script>

import moment from 'moment'
import {Thinning} from "@/providers/thinning";

export default {
    name: "ImportMeasurementData",
    props: {
        location_id: String
    },
    data() {
        return {
            dataset: {},
            thinning_measurements: [],
            tsvForImport: '',
            importError: '',
            thinning: new Thinning(),
        }
    },
    async mounted() {
        this.dataset = await this.$api.getThinningDataset(this.location_id)
        this.thinning_measurements = await this.$api.getThinningMeasurements(this.location_id)
    },
    methods: {
        parseTsv() {
            const lines = this.tsvForImport.split('\n')
            const header = lines[0].split('\t')
            const measurements = [];

            for (let col = 3; col < header.length; col++) {
                const d = moment(header[col]);
                if (!d.isValid()) {
                    this.importError = 'Invalid date in header: ' + header[col];
                    return;
                }
                const key = d.format('YYYY-MM-DD');
                measurements.push({
                    created: d.format(),
                    id: key
                });
            }

            // data rows
            for (let r = 1; r < lines.length; r++) {
                const cols = lines[r].split('\t')
                const key = `${cols[0]}_${cols[1]}_${cols[2]}`;
                for (let col = 3; col < header.length; col++) {
                    const meas = measurements[col - 3];
                    if (!meas.data) {
                        meas.data = {};
                    }
                    meas.data[key] = +cols[col]; // convert to number!
                }
            }

            // store measurements
            measurements.forEach(meas => this.$api.createThinningMeasurement(this.location_id, meas))

            // update measurement summaries...
            // (for some reason this would get triggered multiple times, not sure why - only do it once)
            let updated = false;
            this.$api.getThinningMeasurements(this.location_id).then(ms => {
                if (!updated) {
                    ms.forEach(measurement => this.thinning.refreshThinningMeasurementSummary(measurement));
                    updated = true;
                }
            })
            this.$router.replace({name: 'fruitGrowthRateDetails', params: {location_id: this.location_id}})
        }
    },
}
</script>

<style lang="scss">
    .import__intro {
        max-width:800px;
        display:block;
        padding:20px;
    }
    .import-measurement-data textarea {
        height:60vh;
        width:100%;
        max-height: 800px;
    @media (min-width:768px) {

    }
}
</style>