/**
 * Standard colors used for charts (keep these in sync with colors defined in variables.scss)
 */
export class ChartColors {

  static primary: string = '#51b848';
  static blue: string = '#8DCAC6';
  static lightBlue: string = '#becac6';
  static darkBlue: string = '#0939a4';
  static red: string = '#f53d3d';
  static yellow: string = '#FFEE88';
  static orange: string = '#FFBB88';
  static light: string = '#f4f4f4';
  static border: string = '#1E88E5';
  static white: string = '#ffffff';
  static mediumGray: string = '#606060';
  static transparent: string = 'rgba(255, 255, 255, 0)';
}



