<template>
    <div class="irrigation-record-list">
        <breadcrumb>
            <router-link :to="{name: 'locationDetails', params: {location_id: location_id}}">
                Location
            </router-link>
        </breadcrumb>
        <about-modal topic="irrigation_records" class="mb-3"/>
        <heading title="Irrigation Records"/>

        <spinner-until :condition="loaded">
            <b-row>
                <b-col>
                    <h2>Location</h2>
                    {{ locationTitle }}
                    <br>
                    <router-link class="btn btn-info btn my-2 btn-icon"
                                 :to="{name: 'irrigationDetails', params: {location_id: location_id}}">Go To Irrigation
                        Model
                    </router-link>
                </b-col>
            </b-row>
            <b-button @click="exportCSV" variant="link">export</b-button>
            <b-row>
                <b-table striped bordered responsive class="mb-4" sticky-header="400px"
                         :items="items"
                         :fields="fields"
                         label-sort-asc=""
                         label-sort-desc=""
                         label-sort-clear="">
                    <template #head()="data">
                        <span v-html="data.label"></span>
                    </template>

                    <template v-slot:cell()="row">
                        <div v-if="row.field.key === 'date'">
                            {{ formatDate(row.item[row.field.key]) }}
                        </div>

                        <div v-else>
                            {{ !isNaN(row.item[row.field.key]) ? row.item[row.field.key].toFixed(2) : row.item[row.field.key] }}
                        </div>
                    </template>

                </b-table>
                <div class="column-toggle mb-5">
                    <h3>Show Columns:</h3>
                    <div class="column-toggle__checkboxes">
                        <b-checkbox v-for="field in fields" :key="field.key" v-model="field.isChecked"
                                    @input="toggleColumn($event, field)">
                            <span v-html="field.label"></span>
                        </b-checkbox>
                    </div>
                </div>
            </b-row>
            <b-row>
                <highcharts :options="irrigation.recordsChart" class="chart"></highcharts>
            </b-row>
        </spinner-until>
    </div>
</template>

<script>
import AboutModal from "@/components/AboutModal.vue";
import SpinnerUntil from "@/components/SpinnerUntil.vue";
import {Irrigation} from "@/providers/irrigation";
import Utils from "@/providers/Utils";

export default {
    name: "IrrigationRecordList",
    components: {SpinnerUntil, AboutModal},
    props: {
        location_id: String,
    },
    data() {
        return {
            location: {},
            items: [],
            irrigation: new Irrigation(),
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'water_balance_daily',
                    label: 'Water Balance Daily',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'water_balance_cumulative',
                    label: 'Water Balance Cumulative',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'orchard_et_gpa',
                    label: 'Orchard ET (gal/acre)',
                    sortable: true,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'orchard_et_cumulative',
                    label: 'Cumulative ET',
                    sortable: true,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'rainfall_gpa',
                    label: 'Rainfall (gal/acre)',
                    sortable: true,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'rainfall_cumulative',
                    label: 'Cumulative Rainfall',
                    sortable: true,
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'irrigation',
                    label: 'Irrigation',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'irrigation_cumulative',
                    label: 'Cumulative Irrigation',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
                {
                    key: 'irrigation_recommendation',
                    label: 'Irrigation Recommendation',
                    sortable: true,
                    isChecked: true,
                    thClass: '',
                    tdClass: ''
                },
            ],
        }
    },
    async mounted() {
        this.location = await this.$api.getLocation(this.location_id)
        await this.irrigation.setLocation(this.location)
        await this.irrigation.fetchResults()
        this.items = this.irrigation.resultRows
    },
    computed: {
        loaded() {
            return !!this.location
        },
        locationTitle() {
            return `${this.location.farm} - ${this.location.block} - ${this.location.variety} (${this.location.year})`
        }
    },
    methods: {
        toggleColumn(event, field) {
            field.thClass = field.tdClass = event ? '' : 'd-none'
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
        exportCSV() {
            const header = Object.keys(this.items[0]).map(k => `"${k}"`).join(',') + '\n'
            const data = this.items.map(i => Object.values(i).map(v => `"${v}"`).join(',')).join('\n')

            let csvContent = 'data:text/csv;charset=utf-8,' + header + data
            let encodedUri = encodeURI(csvContent)
            window.open(encodedUri)
        }
    }

}
</script>

<style scoped>


</style>