<template>
    <div class="account-view text-center my-5">
        <div v-if="loggedIn">
            You are logged in as
            {{ $store.state.currentUser.email }}.
            <br/>
            <br/>
            <b-button class="btn btn-danger" @click="deleteUser">Delete Account</b-button>
        </div>
        <div v-else>
            You are not logged in.
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountView",
    computed: {
        loggedIn() {
            return !!this.$store.state.currentUser.email
        },
    },
    methods: {
        deleteUser() {
            this.$bvModal.msgBoxConfirm('Permanently delete your account? This is not reversible!', {
                title: 'Confirm deletion',
                okTitle: 'Delete Account',
                okVariant: 'danger'
            }).then(async (value) => {
                if (value) {
                    await this.$api.deleteUser(this, this.$store.state.currentUser)
                }
            })
        }
    }
}
</script>

<style scoped>
</style>