<template>
    <div class="main-footer">

        <footer v-cloak>
            <div class="container">
                <b-nav class="footer-nav">
                    <router-link to="/">
                        Home
                    </router-link>
                    <li>|</li>
                    <router-link to="/signup">
                        Signup
                    </router-link>
                    <li>|</li>
                    <router-link to="/resetPassword">
                        Reset Password
                    </router-link>
                    <li>|</li>
                    <router-link to="/support">
                        Support
                    </router-link>
                    <li>|</li>
                    <about-modal :as-link="true" topic="privacy_policy" class="footer-nav__extra"/>
                    <li>|</li>
                    <div class="build-info">
                        Build: {{ $store.state.buildVersion }}
                    </div>

                </b-nav>
            </div>

        </footer>

    </div>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
    name: 'MainFooter',
    computed: {
        year() {
            return new Date().getFullYear()
        }
    }
})
</script>


<style lang="scss">

@import './sass/variables_mixins';

.footer-nav__extra {
    line-height: 18px;
    color: #fff;

    > a {
        vertical-align: top;
    }
}

.main-footer {
    position: relative;
    bottom: 0;
    width: 100%;

    footer {
        text-align: center;
        font-size: 14px !important;
        background-color: $dark-green;

        > .container {
            padding-top: 1em;
            padding-bottom: 1em;
            @media (min-width: 1024px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .footer-nav {
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        color: #666;
        align-items: center;

        li {
            line-height: 20px;
            color: $green;
        }

        a {
            color: #eee;
            font-size: 12px;
            text-transform: uppercase;
            padding: 0 1em;
            text-decoration: none;

            &:hover {
                color: #D3FC93;
            }
        }

        @media (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 1em;
            li {
                display: none;
            }
            a, div {
                padding: 5px 0;
                text-align: left;
                width: 32%;
            }
        }

        .about-modal {
            justify-content: flex-start;
        }

        .build-info {
            color: #bbb;
            font-size: 12px;
            text-transform: uppercase;
            padding: 0 1em;
        }
    }
}

</style>
