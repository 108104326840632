<template>
    <div class="nav-bar">
        <b-navbar toggleable="lg">
            <b-navbar-brand>
                <router-link class="logo" to="/">
                    <img src="/malusim-icon-large.png"/>
                    Malusim
                </router-link>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
                <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="x"></b-icon>
                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                </template>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-text v-if="loggedIn">
                        <router-link to="/account" :class="{'disabled': !loggedIn}">
                            <b-icon-person-circle/>
                            {{ $store.state.currentUser.email }}&nbsp;&nbsp;&nbsp;&nbsp;
                        </router-link>
                    </b-nav-text>
                    <b-nav-item class="home-mobile" @click="navigate('/')">
                        <router-link to="/"><span>Home</span></router-link>
                    </b-nav-item>
                    <b-nav-item @click="navigate('/support')">
                        <router-link to="/support"><span>Support</span></router-link>
                    </b-nav-item>

                    <template v-if="!loggedIn">
                        <b-nav-item right>
                            <router-link to="/login">
                                Login
                            </router-link>
                        </b-nav-item>
                        <b-nav-item right>
                            <router-link to="/signup">
                                Sign Up
                            </router-link>
                        </b-nav-item>
                    </template>
                    <template v-else>
                        <b-nav-item right>
                            <a v-b-toggle.nav-collapse @click="logoutUser">Logout</a>
                        </b-nav-item>
                    </template>
                </b-navbar-nav>
            </b-collapse>

        </b-navbar>
    </div>
</template>

<script>

export default {
    name: 'NavBar',
    data() {
        return {
            checklistShown: false
        }
    },
    computed: {
        loggedIn() {
            return !!this.$store.state.currentUser.email
        },
        username() {
            const email = this.$store.state.currentUser.email
            return email ? email.substring(0, email.lastIndexOf("@")) : ''
        },
    },
    methods: {
        logoutUser() {
            this.$api.logoutUser(this)
        },
        navigate(route) {
            this.$router.push(route)
        },
    },
}
</script>

<style lang="scss">

.nav-bar {
    @media (max-width: 991px) {
        margin-top: 36px;
    }

    .logo {
        img {
            width: 32px;
            margin-right: 5px;
        }

        .badge {
            background-color: orange;
            margin-left: 10px;
        }
    }
}

header .navbar {
    justify-content: space-between !important;
}

@media (max-width: 991px) {
    button.navbar-toggler {
        padding: 8px 16px !important;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        flex-grow: initial;
    }
}

.navbar-brand {
    font-weight: bold;
}

</style>
