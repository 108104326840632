<template>
    <spinner-until :condition="formPopulated">
        <template class="data-form">

            <b-form autocomplete="off"
                    @submit.stop.prevent="onSubmit">

                <heading :title="title" :tag="tag">
                    <!-- autosave controls -->
                    <template v-if="autoSave">
                        <span v-if="autosaving">
                            Saving...
                        </span>
                        <span v-else class="text-uppercase">
                            All changes are saved automatically
                        </span>
                        <b-button variant="primary" class="ml-3"
                                  :to="submitSuccessRoute">
                            Done
                        </b-button>
                    </template>
                    <!-- non-autosave controls -->
                    <template v-else>
                        <!-- cancel button -->
                        <b-button variant="danger"
                                  class="me-2"
                                  v-if="cancelRoute"
                                  :to="cancelRoute">
                            {{ cancelButtonLabel }}
                            <b-icon-x-square />
                        </b-button>
                        <!-- submit button -->
                        <b-button type="submit"
                                  variant="primary"
                                  v-if="topSubmitButton"
                                  :disabled="saveButtonDisabled || ($v.form && $v.form.$invalid)">
                            {{ saveButtonLabel }}
                        </b-button>
                    </template>
                </heading>
                <div class="clearfix" />

                <!-- tabbed field groups -->
                <template v-if="fieldGroupTabs">
                    <b-card class="card-tabs" no-body>
                        <b-tabs card v-model="tabIndex">
                            <b-tab v-for="(tab,i) in fieldGroupTabs" :key="i"
                                   :disabled="tabStates[i].disabled"
                                   :title-item-class="tabStates[i].hidden ? 'd-none' : ''">
                                <template #title>
                                    {{ tab.title }}
                                    <b-icon
                                        :icon="tabStates[i].valid ? 'check-circle-fill' : 'exclamation-octagon-fill'"
                                        :variant="tabStates[i].valid ? 'success' : 'danger'" />
                                </template>
                                <data-form-input v-for="fieldName in shownFieldNames(i)"
                                                 v-model="form[fieldName]"
                                                 :key="fieldName"
                                                 :$v="$v"
                                                 :form="form"
                                                 :field-model="fieldModel"
                                                 :field-name="fieldName"
                                                 :horizontal="horizontal"
                                                 :model-instance="modelInstance"
                                                 @input="changed(fieldName)" />
                            </b-tab>
                            <template #tabs-end>
                                <!-- Next button -->
                                <b-btn size="sm" class="mb-2 ml-1 btn-with-icon btn-next"
                                       v-if="showNextTabButton"
                                       :disabled="disableNextTabButton"
                                       @click="nextTab">
                                    Next Tab
                                    <b-icon-arrow-right-square />
                                </b-btn>
                            </template>
                        </b-tabs>
                        <b-btn size="sm" class="btn-with-icon btn-next btn-next-bottom"
                               v-if="showNextTabButton && bottomNextButton"
                               :disabled="disableNextTabButton"
                               @click="nextTab">
                            Next Tab
                            <b-icon-arrow-right-square />
                        </b-btn>
                    </b-card>

                </template>

                <!-- non-tabbed field groups -->
                <template v-if="!fieldGroupTabs">
                    <b-row :cols="formColumns">
                        <!-- form inputs for specified fields, one group per column -->
                        <b-col v-for="(fieldGroup,i) in fieldGroups"
                               :key="`input${i}`"
                               class="mb-3">
                            <div class="frame p-3 form-elements">
                                <data-form-input v-for="fieldName in shownFieldNames(i)"
                                                 v-model="form[fieldName]"
                                                 :key="fieldName"
                                                 :$v="$v"
                                                 :form="form"
                                                 :field-model="fieldModel"
                                                 :field-name="fieldName"
                                                 :horizontal="horizontal"
                                                 :model-instance="modelInstance"
                                                 @input="changed(fieldName)" />
                                <slot name="fieldName" v-bind:form="form" />
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <div v-if="bottomSubmitButton">
                    <b-button class="bottom-submit"
                              type="submit"
                              variant="primary"
                              :disabled="saveButtonDisabled || ($v.form && $v.form.$invalid)">
                        {{ saveButtonLabel }}
                    </b-button>
                </div>

            </b-form>

        </template>
    </spinner-until>
</template>

<script>

/**
 * A generic form for creating or updating an instance of a model, with validation.
 *
 * Requires a field model specifying the types and validation behaviors of the model's fields,
 * as well as a grouped list of names of fields to display on the form.
 *
 * The fields to display are specified via the fieldGroups prop, e.g.:
 *   const fieldGroups = [['name', 'email'], ['address', 'city']]
 *
 * By default, each top-level field groups will be displayed as a column. You can optionally specify the
 * fieldGroupTabs prop to display the fields in tabs rather than columns, e.g.:
 *   const fieldGroupTabs = [{title: 'Tab 1', requireValidity: true}, ...]
 *
 * Setting the requireValidity attribute on a tab means that navigation to subsequent tabs is disabled as long
 * as any field on the tab is invalid.
 *
 * Promises must also be provided to load or initialize the model instance, and to save it.
 *
 * The form will display the provided success/error messages after saving, and redirect to the provided
 * route if saving is successful.
 *
 * Each field in the field model should specify the following attributes:
 *
 * * [key] (required): This is normally the same as the DB field name
 *
 * * dbFieldName (optional): This must be specified, along with an alternate key, if you wish to supply
 *   two alternate field definitions with different behaviors (e.g. label, input type, validation rules, display
 *   conditions) for the same DB field but for use in different form contexts. If more than one such alternate
 *   field is displayed on the same form, you must ensure that at most one of the field is visible and enabled
 *   at the same time - otherwise saving behavior will be unpredictable.
 *
 * * label (required): The label to be displayed for the form input.
 *
 * * type (required): The type of input to be displayed for the field. Possible values are:
 *      radios
 *      check
 *      checks
 *      select
 *      multiselect
 *      typeahead
 *      textarea
 *      number
 *      password
 *      text
 *      number
 *      date
 *      tel
 *      email
 *      hidden
 *
 * * validations (optional): A dictionary of vuelidate validations (built-in or custom) to be applied to the field.
 *
 * * invalidFeedback (optional): A dictionary of messages to be displayed below the field if the corresponding
 *   validations fail.
 *
 * * options: A dictionary of possible options (key/label) to be displayed for a select 'checks', or 'radios' input.
 *
 * * loadOptions: (optional) A function (async) that resets the value of 'options' by some dynamic means, such as
 *   by fetching values from the API. This is called only once when the corresponding DataFormInput is created, so
 *   there is no way to change the list of options in response to a change to some other input on the form - this
 *   would need to be done by displaying alternate inputs, each with its own appropriately filtered list of options.
 *
 * The following optional attributes are specified as functions, which allow you to read/write other model attributes
 * for various purposes. They are each passed three arguments:
 *
 *     1. The simplified form data model, allowing direct access to model instance attributes;
 *     2. The full Vuelidate validation model, which has a more complex structure but allows access to more metadata,
 *        including the current value of some input types such as DataFormInputMultiselect - for example:
 *
 *        onChange(model, $vform, fieldModel) {
 *            model.foobar = 1
 *            $vform.goobar.$model = null
 *        }
 *
 *     3. The fieldModel object, which gives access to info about other fields, such as options.
 *
 * * getDescription(): Returns a string to be displayed below the input on the form.
 *
 * * onChange(): Performs some action on the validation model when the value of the current field changes -
 *   for example, clearing another field if a checkbox is checked.
 *
 * * showIf(): Determines whether the field should be shown or hidden on the form.
 *
 * * disableIf(): Determines whether the field should be disabled on the form.
 *
 * A custom validator on a field can also generate 'warnings', which will be displayed via the valid-feedback
 * attribute of a b-form-group. These do not prevent form submission, but may relate useful information.
 *
 * Example:
 *   validations: {
 *     sameAsEmail: function (value) {
 *       // 'this' points to the DataForm component (vuelidate.js.org/#sub-accessing-component)
 *       const user = this.modelInstance
 *       if (value == user.email) {
 *         this.warnings.myField = "Value is same as email (but that's OK!)"
 *
 * Hidden fields should be used when their value is calculated or set via actions on other fields
 * on a form, and you wish to save this value - they are necessary because if the field were left out
 * of the fieldGroups, or specified with showIf always set to false, the value would not be saved.
 */

import Vue from 'vue'
import Validation from '@/providers/Validation'
import {validationMixin} from 'vuelidate'
import * as _ from 'lodash'

export default Vue.extend({
    name: 'DataForm',
    mixins: [validationMixin],
    props: {
        title: {
            type: String,
            default: null
        },
        tag: String,
        horizontal: {
            type: Boolean,
            default: false
        },
        autoSave: {
            type: Boolean,
            default: false
        },
        saveButtonLabel: {
            type: String,
            default: 'Save'
        },
        cancelButtonLabel: {
            type: String,
            default: 'Cancel'
        },
        saveButtonDisabled: Boolean,
        bottomNextButton: Boolean,
        bottomSubmitButton: Boolean,
        topSubmitButton: {
            type: Boolean,
            default: true
        },
        fieldGroups: Array,
        fieldGroupTabs: Array,
        fieldModel: Object,
        loadModelInstance: {type: Function, default: null},
        onSubmitHandler: Function,
        submitSuccessMessage: String,
        submitErrorMessage: String,
        submitSuccessRoute: String,
        cancelRoute: String,
    },
    data() {
        if (this.fieldGroupTabs && this.fieldGroupTabs.length !== this.fieldGroups.length) {
            throw('DataForm: fieldGroupTabs must have same length as fieldGroups!')
        }

        // NB: must compute the flattened field list in advance rather than directly in the return structure!
        // (otherwise buildFormModel will be given an empty field list)
        const fieldList = _.flatten(this.fieldGroups)
        let form = Validation.buildFormModel(this.fieldModel, fieldList)

        // track validity/disablement status of tabs
        // (create a separate structure so we don't modify the fieldGroupTabs prop)
        const tabStates = _.map(this.fieldGroupTabs, (tab) => {
            return {valid: false, disabled: true}
        })

        // don't show form until populated - otherwise onChange methods (etc.) in field model may trigger prematurely!
        const formPopulated = false

        return {
            modelInstance: {},
            fieldList: fieldList,
            form: form,
            formPopulated: formPopulated,
            autosaving: false,
            warnings: {},
            tabIndex: 0,
            tabStates: tabStates
        }
    },
    validations() {
        return Validation.buildFormValidations(this.fieldModel, this.fieldList)
    },
    computed: {
        formColumns() {
            let columns = this.fieldGroups.length
            if (this.$mq === 'sm') {
                columns = 1
            } else if ((this.$mq === 'md') || (this.$mq === 'lg')) {
                columns = Math.min(columns, 2)
            } else {
                columns = Math.min(columns, 4)
            }
            return columns
        },
        showNextTabButton() {
            return this.tabIndex < this.tabStates.length - 1 && this.nextUnhiddenTabIndex !== null && this.nextUnhiddenTabIndex > this.tabIndex
        },
        disableNextTabButton() {
            return this.tabStates.length &&
                this.tabIndex < this.tabStates.length - 1 &&
                this.tabStates[this.tabIndex + 1].disabled
        },
        nextUnhiddenTabIndex() {
            let index = _.findIndex(this.tabStates, tab => !tab.hidden, this.tabIndex + 1)
            return index !== -1 ? index : null
        }
    },
    methods: {
        refreshTabValidity() {
            if (this.tabStates.length) {
                _.each(this.tabStates, (tab, i) => { // each fieldGroup corresponds to a tab
                    tab.valid = true
                    // check if any field on this tab is invalid
                    _.each(this.fieldGroups[i], (field) => {

                        //Vuelidate validation still might set a field to invalid when
                        //it is being hidden with showIf. Ignore these fields when checking tab validity
                        let showIf = this.fieldModel.fields[field].showIf
                        let shown = showIf ? showIf(this.form) : true

                        if (this.$v.form[field].$invalid && shown) {
                            tab.valid = false
                        }
                    })

                    let allInvisible = _.every(this.fieldGroups[i], (field) => {
                        let hidden = this.fieldModel.fields[field].type === 'hidden'
                        let showIf = this.fieldModel.fields[field].showIf
                        let shown = showIf ? showIf(this.form) : true
                        return !shown || hidden
                    })
                    tab.hidden = allInvisible

                    let fieldGroupTab = this.fieldGroupTabs[i]
                    // get the tab states of locking tabs for the current tab
                    let lockingTabStates = _.map(fieldGroupTab.lockedBy, (e) => this.tabStates[e])
                    // if any locking tab state is false disable current tab
                    tab.disabled = _.some(lockingTabStates, {valid: false})
                })
            }
        },
        nextTab() {
            this.tabIndex = this.nextUnhiddenTabIndex
        },
        changed(fieldName) {
            if (this.autoSave) {
                // important: wait for tab validity refresh!
                Vue.nextTick(() => {
                    this.doAutoSave()
                })
            }
            this.$emit('changed', fieldName, this.form)
        },
        onSubmit(evt) {
            this.$v.form.$touch()
            if (!this.$v.form.$anyError) {
                this.doManualSave()
            }
        },
        async doManualSave() {
            Validation.extractFormModel(this.form, this.$v.form, this.fieldModel, this.modelInstance, this.fieldList)
            let response = {}
            try {
                response = await this.onSubmitHandler(this.modelInstance, this.$v.form, this.isValid())
                if (this.submitSuccessMessage) {
                    this.$toasted.success(this.submitSuccessMessage)
                }
                if (this.submitSuccessRoute) {
                    await this.$router.push(this.submitSuccessRoute)
                }
            } catch (e) {
                if (this.submitErrorMessage) {
                    this.$toasted.error(this.submitErrorMessage)
                }
            }
        },
        async doAutoSave() {
            Validation.extractFormModel(this.form, this.$v.form, this.fieldModel, this.modelInstance, this.fieldList)
            let response = {}
            try {
                this.autosaving = true
                response = await this.onSubmitHandler(this.modelInstance, this.$v.form, this.isValid())
            } catch (e) {
                this.$toasted.error('Autosave Error') // TODO: customize??
            } finally {
                setTimeout(() => { // don't hide the saving message too quickly
                    this.autosaving = false
                }, 500)
            }
        },
        shownFieldNames(fieldGroupIndex) {
            const f = _.filter(this.fieldGroups[fieldGroupIndex], (fieldName) => {
                let field = this.fieldModel.fields[fieldName]
                if (field.showIf) {
                    return field.showIf(this.form, this.$v.form, this.fieldModel)
                }
                return true
            })
            return f
        },
        fieldDisabled(fieldName) {
            return this.disabledFields && this.disabledFields.includes(fieldName)
        },
        isValid() {
            if (this.tabStates.length) {
                return _.reduce(this.tabStates, ((prev, tab) => {
                    return prev && tab.valid
                }), true)
            } else {
                return !this.$v.form.$invalid
            }
        }
    },
    async mounted() {
        this.modelInstance = this.loadModelInstance ? await this.loadModelInstance() : {}
        Validation.populateFormModel(this.form, this.fieldModel, this.modelInstance, this.fieldList)
        if (this.autoSave) {
            // trigger this to update is_valid if not set (equipment case)
            // (NB: must wait a tick to allow form validity to update!)
            Vue.nextTick(() => {
                this.doAutoSave()
            })
        }
        this.formPopulated = true // hide spinner; enable onChange methods (etc.) in field model
    },
    watch: {
        // if using tabs, update tab validity/disablement
        form: {
            immediate: true,
            deep: true,
            handler: function (newVal, oldVal) {
                this.refreshTabValidity()
            }
        },
        tabIndex: {
            handler: function (newVal, oldVal) {
                this.$emit('changed-tab', newVal)
            }
        }
    }
})

</script>

<style lang="scss">
.btn-next {
    border: none !important;

    svg {
        margin-left: 5px;
    }

    &.btn-next-bottom {
        align-self: end;
        max-width: 200px;
        margin: 0 10px 10px 0;
    }
}

.bottom-submit {
    float: right;
    margin: 15px;
}

</style>