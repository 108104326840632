import {SpeechRecognition} from "@capacitor-community/speech-recognition"
import {TextToSpeech} from '@capacitor-community/text-to-speech'

import Vue from 'vue'
import * as _ from 'lodash'
import {Debounce} from 'lodash-decorators'
import {Capacitor} from "@capacitor/core"
import {Thinning} from "@/providers/thinning"


/**
 * Service for speech recognition and TTS functions, used by ThinningMeasurementDetails component
 */

export class Speech {

    // we need this to track whether we are listening or speaking - without it we get errors
    // when speech recognition and TTS fight over control of the audio session!
    public isListening = false

    public currentSpeechState: any

    thinning: any
    location: any
    dataset: any
    measurement: any
    previousMeasurement: any

    constructor() {
    }

    public async init(location: any, dataset: any, measurement: any) {
        const platform = Capacitor.getPlatform()
        if (platform != 'web') {
            if (!SpeechRecognition.hasPermission()) {
                console.log('requesting permission')
                SpeechRecognition.requestPermission()
            }
        }

        this.location = location
        this.dataset = dataset
        this.measurement = measurement
        this.thinning = new Thinning()
        await this.thinning.setDataset(dataset, location)

        this.previousMeasurement = this.thinning.getPreviousMeasurement(measurement)

        // console.log('platform', platform)
        // console.log('location', location)
        // console.log('dataset', dataset)
        // console.log('measurement', measurement)
        // console.log('previousMeasurement', this.previousMeasurement)

        this.addResultListener()
    }

    protected addResultListener() {
        console.log('adding listener')
        SpeechRecognition.addListener("partialResults", (data: any) => {
            console.log('listener called', JSON.stringify(data))
            if (this.isListening) {
                if (Capacitor.getPlatform() == 'android') {
                    this.handleRecognizedSpeechAndroid(data)
                } else {
                    this.handleRecognizedSpeechIos(data)
                }
            }
        })
    }

    // Called when a microphone button is clicked in ThinningMeasurementDetails.
    // NB: to handle possibly multiple clicks, we don't disable the microphone button,
    // as that causes the focus to go to the neighboring input, which brings up the keyboard.
    // Instead we just ignore repeat clicks if we're already listening.
    public beginListening(t, c, f) {
        console.log('beginListening')
        if (this.isListening) {
            console.log('beginListening: already listening, returning')
            return;
        }

        this.currentSpeechState = new SpeechState(t, c, f,
            this.measurement,
            _.toNumber(this.dataset.cluster_count),
            _.toNumber(this.dataset.flower_count)
        )

        if (Capacitor.getPlatform() == 'android') {
            this.resumeListeningAndroid()
        } else {
            this.resumeListeningIos()
        }
    }

    ceaseListening() {
        console.log('ceaseListening');
        SpeechRecognition.stop() // NB: don't await
        this.currentSpeechState = null
        this.isListening = false
        //     // TODO: update UI
        this.say('Recognition stopped')
    }

    public async resumeListeningIos() {
        // console.log('resumeListeningIos')
        await this.say(this.currentSpeechState.feedback)
        await this.say(this.currentSpeechState.prompt)

        this.isListening = true
        try {
            console.log('starting speech rec (iOS)')
            SpeechRecognition.start({
                maxResults: 2,
                partialResults: true,
            })
        } catch (e) {
            console.log('error in resumeListeningIos', e)
        }
    }

    // NB: this function is debounced so that it is only invoked after a designated interval -
    // on iOS speech recognition will return multiple partial matches (e.g. "20" then "23" when we say "23"),
    // so we need to wait for the last (hopefully best) match and then stop recognition manually.
    @Debounce(500)
    async handleRecognizedSpeechIos(data: any) {
        console.log('handleRecognizedSpeechIos', data)
        console.log('this.isListening', this.isListening)

        if (this.isListening) {
            await SpeechRecognition.stop()
            this.isListening = false
        }

        const input = (data.matches.length > 0 ? data.matches[0].toLowerCase() : null);
        console.log('input', input)
        const keepGoing = await this.handleInput(input); // false if stop command handled

        console.log('keepGoing', keepGoing)
        if (keepGoing) {
            this.resumeListeningIos()
        } else {
            this.say('Recognition stopped')
        }
    }

    public async resumeListeningAndroid() {
        // console.log('resumeListeningAndroid')
        await this.say(this.currentSpeechState.feedback)
        await this.say(this.currentSpeechState.prompt)

        this.isListening = true
        try {
            console.log('starting speech rec (Android)')
            const message = await SpeechRecognition.start({
                maxResults: 1,
                partialResults: false,
                popup: false, // android only - must set to false for listener to be called!
            })
            console.log('message', message)
            if (message.matches && message.matches.length) {
                this.handleRecognizedSpeechAndroid(message.matches[0])
            }
        } catch (e) {
            console.log('error in resumeListeningAndroid', e)
            this.handleRecognizedSpeechAndroid(null)
        }
    }

    async handleRecognizedSpeechAndroid(input: string) {

        console.log('handleRecognizedSpeechAndroid', input)
        const keepGoing = await this.handleInput(input); // false if stop command handled
        console.log('keepGoing', keepGoing)
        if (keepGoing) {
            this.resumeListeningAndroid()
        } else {
            this.say('Recognition stopped')
        }
    }

    public say(text: string) {
        console.log('say: ' + text);
        //     const rate = (this.platform.is('ios') ? 1.6 : 1);
        return TextToSpeech.speak({
            text: text,
            //lang: 'en-US',
            rate: 1.0,
            pitch: 1.0,
            volume: 1.0,
            category: 'ambient',
        });
    }

    // handle a single input recognized from the user's speech
    // return false if it's a stop request; true otherwise
    async handleInput(input: string) {
        const ss = this.currentSpeechState;
        let doneMeasuring = false;
        console.log('handleInput', input);

        if (input && input.toLowerCase() == 'stop') { // break main loop
            this.ceaseListening()
            return false
        }

        if (input === null || input === undefined || input == '') {
            ss.feedback = 'Not recognized.';
        } else if (input.toLowerCase() == 'help') {
            ss.feedback = ss.getHelpSummary();
        } else if (input.toLowerCase() == 'skip') {
            ss.feedback = 'Skip.';
            ss.prompt = 'Say next cluster number.';
            ss.listeningFor = listeningForCluster;
        } else if (input.toLowerCase() == 'back') {
            ss.listeningFor = listeningForValue; // may have been listening for next cluster
            ss.f--;
            ss.feedback = 'Back.';
            ss.prompt = `Fruitlet ${ss.f}.`;
        } else {
            // get cluster number or measurement value
            const num = this.getNumber(input);

            if (!num) {
                ss.feedback = 'Not Recognized.';
            } else if (ss.listeningFor == listeningForValue) {
                doneMeasuring = this.handleMeasurementValue(num, ss);
            } else if (ss.listeningFor == listeningForCluster) {
                this.handleClusterJump(num, ss);
            }
        }

        if (doneMeasuring) {
            await this.say(ss.feedback)
            await this.say('All clusters have been measured.')
            this.ceaseListening()
            return false; // break main loop
        } else {
            return true;
        }
    }

    // returns true if all clusters for the current tree have been measured
    handleMeasurementValue(num: number, ss: SpeechState): boolean {
        ss.feedback = `${num}. `;
        if (num > 50 || num < 0) {
            ss.feedback += 'This value is outside the normal range.';
        }
        Vue.set(this.measurement.data, `${ss.t}_${ss.c}_${ss.f}`, num)

        // move to next cluster or next fruitlet
        // (if we've done the last flower for the cluster OR
        // there was no data for the next flower in the previous measurement, indicating that it's gone)
        ss.f++;
        if (ss.f > ss.flowerCount ||
            !this.thinning.measurementHasDataForFlower(this.previousMeasurement, ss.t, ss.c, ss.f)) {
            // check if done measuring (NB: we only do this at the end of a cluster, not after every flower)
            // if (this.thinning.measurementHasDataForAllClusters(this.measurement, ss.t, this.dataset.cluster_count)) {
            //     return true; // done measuring
            // } else {
                ss.prompt = 'Say next cluster number.';
                ss.listeningFor = listeningForCluster;
            // }
        } else {
            ss.prompt = `Fruitlet ${ss.f}.`;
        }
        return false // not done measuring
    }

    handleClusterJump(num: number, ss: SpeechState) {
        if (num > ss.clusterCount) {
            ss.feedback = num + ': no such cluster.';
            ss.prompt = 'Say next cluster number.';
        // } else if (this.thinning.measurementHasDataForCluster(this.measurement, ss.t, num)) {
        //     ss.feedback = `Cluster ${num} has already been measured.`;
        //     ss.prompt = 'Say next cluster number.';
        } else {
            ss.c = num;
            ss.f = 1;
            ss.feedback = ss.getClusterSummary(true);
            ss.prompt = `Fruitlet ${ss.f}.`;
            ss.listeningFor = listeningForValue;
        }
    }

    // recognize a number from common matches
    getNumber(input: string) {
        console.log('getNum: input: ', '"' + input + '"');
        const num = _.toNumber(input);
        if (!_.isNaN(num)) {
            console.log('getNum: !isNaN case: ', num);
            return num;
        } else {
            console.log('getNum: other case: ', input);
            const options = [
                [],
                ['one', 'won'],
                ['two', 'to', 'too'],
                ['three', 'tree', 'free'],
                ['four', 'for', 'fore'],
                ['five'],
                ['six', 'sex'],
                ['seven', 'seventh'],
                ['eight', 'ate'],
                ['nine'],
            ]
            let val = null;
            if (_.isString(input)) {
                _.each(options, (opt, n) => {
                    const i = opt.indexOf(input.toLowerCase());
                    if (i != -1) {
                        console.log('getNum: matched: ', n);
                        val = n;
                    }
                });
            }
            return val;
        }
    }
}

// -------------------------------------------

/**
 * Class to represent the current state of the recognition/speech loop, including:
 * - what tree/cluster/fruitlet we are currently on
 * - whether we are listening for a measurement value or a cluster number
 * - what feedback we need to speak in reaction to the most recent input
 * - what prompt we need to speak to the user for the next input
 */

const listeningForValue = 'VALUE';
const listeningForCluster = 'CLUSTER';

class SpeechState {

    public t: number  // current tree
    public c: number  // current cluster
    public f: number  // current fruitlet

    feedback = ''
    prompt = ''
    listeningFor: string = listeningForValue;

    constructor(t: number,
                c: number,
                f: number,
                public measurement: any,
                public clusterCount: number,
                public flowerCount: number) {
        this.t = t
        this.c = c
        this.f = f
        // initial prompt
        // NB: not warning here if cluster already has measurements
        this.prompt = `Cluster ${this.c}, Fruitlet ${this.f}. `;
    }

    getHelpSummary() {
        if (this.listeningFor == listeningForCluster) {
            return 'Say next cluster number.';
        } else {
            // TODO: describe how many clusters/fruitlets have been measured
            let summary = `Tree ${this.t}, Cluster ${this.c}`;
            summary += '' // temp
            for (let i = 1; i <= this.flowerCount; i++) {
                const val = this.measurement.data[`${this.t}_${this.c}_${i}`];
                if (val) {
                    summary += `Fruitlet ${i}, ${val}. `;
                }
            }
            return summary;
        }
    }

    // shorter summary for starting a cluster, with prompt for next (first) fruitlet
    getClusterSummary(withCurrentValues: boolean) {
        let summary = `Cluster ${this.c}. `;

        summary += '' // temp
        if (withCurrentValues) {
            let vals = [];
            for (let i = 1; i <= this.flowerCount; i++) {
                const val = this.measurement.data[`${this.t}_${this.c}_${i}`];
                if (val) {
                    vals.push(val);
                }
            }
            if (vals.length) {
                summary += `${vals.length} measurements entered.`;
            }
        }
        return summary;
    }
}
