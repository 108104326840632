/**
 * Useful functions for mapping between our field models (e.g. models/Participant.js) and structures
 * used by Vuelidate for validation. These functions allow you to select which fields from a given
 * field model are to be displayed and validated on a given form.
 *
 * These functions are used mainly in DataForm.vue.
 */

import * as _ from 'lodash'

export default {

    /**
     * Builds the form data model for Vuelidate, based on the selected field names from the given field model.
     * This is just an object with a key for each field name, the value of which is an object that Vuelidate
     * uses to track various aspects of the state of the input ($invalid, $dirty, etc.)
     */
    buildFormModel(fieldModel, fieldNames) {
        const form = {}
        _.each(fieldNames, (fn) => {
            form[fn] = null
        })
        return form
    },

    /**
     * Populates the form validation stucture for Vuelidate, using validations for the selected field names
     * defined in the given field model (e.g. Participant).
     */
    buildFormValidations(fieldModel, fieldNames) {
        const vs = {}
        _.each(fieldNames, (fn) => {
            const field = fieldModel.fields[fn]
            if (field) {
                if (field.validations) {
                    vs[fn] = field.validations
                } else {
                    vs[fn] = {}
                }
            } else {
                new Error('ouch')
            }
        })

        return {form: vs}
    },

    /**
     * Populates the form data model for Vuelidate, using field values from the given model instance.
     * This is a stripped-down version of Vuelidate's form data model, without all the metadata attributes -
     * to make it easier to refer to input values as e.g. "form.email" instead of "$v.email.$model",
     * and to pass the entire updated model instance to the back end for saving, in the same format in which
     * it was loaded.
     */
    populateFormModel(form, fieldModel, modelInstance, fieldNames) {
        _.each(fieldNames, (fn) => {
            const dbFieldName = this.getDbFieldName(fieldModel, fn)
            const field = fieldModel.fields[fn]
            if (field) {
                if (field.type === 'checks') {
                    // for a checkbox group, convert a SET db value (e.g. "PRIMARY, SPECIALIST")
                    // to array for binding to control
                    if (modelInstance[fn]) {
                        form[fn] = modelInstance[dbFieldName].split(',')
                    }
                    // } else if (field.type === 'check') {
                    //     form[fn] = Utils.toBoolean(modelInstance[dbFieldName])
                } else {
                    form[fn] = modelInstance[dbFieldName]
                }
            }
        })
    },

    /**
     * Returns the value of the dbFieldName attribute for the given field, or the given fieldName if this is not present.
     * This is relevant when a given field model has multiple fields for the same DB column, but with different behaviors
     * w.r.to validation, etc. In most cases, the DB column name is the same as the fieldName.
     */
    getDbFieldName(fieldModel, fieldName) {
        const field = fieldModel.fields[fieldName]
        if (field.dbFieldName) {
            return field.dbFieldName
        } else {
            return fieldName
        }
    },

    /**
     * Copies the Vuelidate form model values back to the given model instance, prior to saving it to the DB.
     * form is the simplified form data model (see populateFormModel), while $vform is the full Vuelidate form model.
     */
    extractFormModel(form, $vform, fieldModel, modelInstance, fieldNames) {
        _.each(fieldNames, (fieldName) => {
            const field = fieldModel.fields[fieldName]
            const dbFieldName = this.getDbFieldName(fieldModel, fieldName)

            // don't save values of hidden or disabled fields
            if (field.showIf && !field.showIf(form, $vform)) {
                return
            }
            if (field.disableIf && field.disableIf(form, $vform)) {
                return
            }

            if (field.type === 'checks') {
                // for a checkbox group, convert an array of selected items back to
                // a SET db value (e.g. "PRIMARY, SPECIALIST")
                if ($vform[fieldName] && $vform[fieldName].$model) {
                    modelInstance[dbFieldName] = $vform[fieldName].$model.join(',')
                }
            } else {
                modelInstance[dbFieldName] = $vform[fieldName].$model
            }
        })
    },

    validatePassword(value) {
        value = value || ''
        return (value.length >= 4) &&
            (value.search(/[a-z]/i) >= 0) &&
            (value.search(/[0-9]/) >= 0)
    },

    validatePhoneNumber(value) {
        let strippedValue = (value || '').replace(/\D/g, '')
        let otherValue = (value || '').replace(/ x/, '').replaceAll('-', '')
        return (strippedValue.length === 0) ||
            ((strippedValue.length >= 10) && (otherValue === strippedValue))
    },

    formatPhoneNumber(value) {
        if ((value !== undefined) && (typeof (value) == 'string')) {
            if (value.charAt(0) !== '+') {
                value = value.replaceAll(/\D/g, '')
                if (value.length > 3) {
                    value = value.substr(0, 3) + '-' + value.substr(3)
                }
                if (value.length > 7) {
                    value = value.substr(0, 7) + '-' + value.substr(7)
                }
                if (value.length > 12) {
                    value = value.substr(0, 12) + ' x' + value.substr(12)
                }
            }
        }
        return value
    },
}
