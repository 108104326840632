<template>
    <div class="spray-record-list">
        <breadcrumb>
            <router-link :to="{name: 'locationDetails', params: {location_id: location_id}}">
                Location
            </router-link>
        </breadcrumb>
        <about-modal topic="spray_records" />
        <heading title="Spray Records" />

        <data-table-local-sort :items="sprayRecords"
                               :fields="fields">
            <template v-slot:customField="slotProps">
                <div v-if="slotProps.row.field.key === 'stage'" class="d-flex">
                    {{ fieldModel.fields.stage.options[slotProps.row.item.stage] }}
                </div>
                <div v-else-if="slotProps.row.field.key === 'actions'" class="d-flex">
                    <spray-record-edit-modal :location_id="location_id" :model-instance="slotProps.row.item"
                                             class="me-2">
                        <b-icon icon="pencil" />
                    </spray-record-edit-modal>
                    <b-button @click="deleteSprayRecord(slotProps.row.item)">
                        <b-icon icon="dash-circle" />
                    </b-button>
                </div>
                <div v-else-if="slotProps.row.field.key === 'date'">
                    {{ formatDate(slotProps.row.item[slotProps.row.field.key]) }}
                </div>
                <div v-else>
                    {{ slotProps.row.item[slotProps.row.field.key] }}
                </div>
            </template>
        </data-table-local-sort>

        <spray-record-edit-modal :location_id="location_id">
            <b-icon icon="plus" />
            Add a Spray Record
        </spray-record-edit-modal>
    </div>
</template>

<script>
import DataTableLocalSort from "@/components/DataTableLocalSort.vue"
import Heading from "@/components/Heading.vue"
import AboutModal from "@/components/AboutModal.vue"
import SprayRecordEditModal from "@/components/SprayRecordEditModal.vue"
import SprayRecordFields from "@/models/sprayRecordFields";
import Utils from "@/providers/Utils";

export default {
    name: "SprayRecordList",
    components: {SprayRecordEditModal, AboutModal, Heading, DataTableLocalSort},
    props: {
        location_id: String
    },
    data() {
        return {
            fields: [
                {key: 'chemical', sortable: true, label: 'Chemical'},
                {key: 'date', sortable: true, label: 'Date'},
                {key: 'stage', sortable: true, label: 'Stage'},
                {key: 'actions', sortable: false, label: 'Actions'}
            ],
            fieldModel: SprayRecordFields,
            sprayRecords: []
        }
    },
    async mounted() {
        this.sprayRecords = await this.$api.getSprayRecords(this.location_id)
    },
    methods: {
        async deleteSprayRecord(spray_record) {
            this.$bvModal.msgBoxConfirm('Permanently delete this Spray Record?', {
                title: 'Confirm deletion',
                okTitle: 'Delete'
            }).then(async (value) => {
                if (value) {
                    await this.$api.deleteSprayRecord(this.location_id, spray_record)
                    this.$toasted.info('Spray Record deleted')
                    this.$router.go(0)
                }
            })
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
    },
}
</script>

<style scoped>

</style>