<template>
    <div class="support">
        <h1>Malusim Support</h1>
        <p>
            If you have questions about using the Malusim app, please first consult the application
            help, which is accessible via the yellow <strong>HELP</strong>
            links on various pages throughout the app.
        </p>
        <hr/>
        <p>
            Here are alternate links to the available help topics:
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="location"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="location_details"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="carbohydrate"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="measurement_details"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="spray_records"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="thinning"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="irrigation"/>
        </p>
        <p>
            <AboutModal :show-topic-label="true" topic="irrigation_records"/>
        </p>

        <hr/>
        <p>
            If the above documentation does not resolve your question, you can contact our support team
            via email here:
        </p>
        <p>
            <a href="mailto:support+oitjbbeub9@gorges.us">
                <b-button class="info">
                    Contact Support
                </b-button>
            </a>
        </p>
    </div>
</template>

<script>

import AboutModal from "@/components/AboutModal.vue"

export default {
    name: "SupportView",
    components: {AboutModal},
}
</script>

<style lang="scss">
.about-modal {
    &.show-topic-label {
        justify-content: center;

        button {
            min-width: 350px;
        }
    }
}
</style>