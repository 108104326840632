export default {
    fields: {
        id: {
            label: 'ID',
            type: 'text',
        },
        date: {
            label: 'Date',
            type: 'date',
        },
        stage: {
            label: 'Stage',
            type: 'select',
            options: {
                'bloom': 'Bloom',
                'petal_fall': 'Petal Fall',
                '8_10': '8-10mm',
                '10_12': '10-12mm',
                '12_14': '12-14mm',
                '14_16': '14-16mm',
                '16_18': '16-18mm',
                '18_20': '18-20mm',
                '20_22': '20-22mm'
            },
        },
        chemical: {
            label: 'Chemical',
            type: 'text',
        },
    }


}
