import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: {}, // Firebase auth user object returned by sign in functions
    currentDbUser: {}, // user record from DB
    buildVersion: '2023-05-11', // update this for new releases!
  }
})
