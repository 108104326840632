<template>
    <div class="user-reset-password">
        <b-row class="justify-content-md-center">
            <b-col cols="12" md="6" md-offset="3">
                <data-form v-if="state === SUBMITTING"
                           tag="user-reset"
                           title="Forgot Password"
                           save-button-label="Send Reset Email"
                           :save-button-disabled="submitting"
                           cancelRoute="/"
                           :field-model="userFields"
                           :field-groups="fieldGroups"
                           :on-submit-handler="onSubmit">
                </data-form>
                <heading v-else
                         title="Forgot Password"
                         tag="user-reset-password-title" />

                <!-- TODO: show something while waiting for form to process -->

                <div v-if="state === SUBMITTED">
                    <b-alert show variant="success">
                        Request submitted
                    </b-alert>
                </div>

                <div v-if="state === SUBMIT_ERROR">
                    <b-alert show variant="danger">
                        Error submitting request
                    </b-alert>
                </div>

                <div v-if="state === CONFIRMING">
                    <b-alert show variant="info">
                        Confirming request
                    </b-alert>
                </div>

                <div v-if="state === CONFIRMED">
                    <b-alert show variant="success">
                        <p>
                            Password is reset
                        </p>
                        <p>
                            <router-link to="/">
                                Log in
                            </router-link>
                        </p>

                    </b-alert>
                </div>

                <div v-if="state === CONFIRM_ERROR">
                    <b-alert show variant="danger">
                        Can't confirm password reset request
                    </b-alert>
                </div>

            </b-col>
        </b-row>
    </div>

</template>

<script>

import Vue from 'vue'
import UserFields from '@/models/User'

/**
 * Page for a User to reset their password
 */
export default Vue.extend({
    name: 'UserResetPassword',
    data() {
        return {
            userFields: UserFields,
            fieldGroups: [
                ['email'],
            ],
            SUBMITTING: 1,
            SUBMITTED: 2,
            SUBMIT_ERROR: 3,
            CONFIRMING: 4,
            CONFIRMED: 5,
            CONFIRM_ERROR: 6,
            state: 1,
            submitting: false,
        }
    },
    computed: {
        email() {
            return this.$route.params.email
        },
        confirm() {
            return this.$route.params.confirm
        }
    },
    methods: {
        onSubmit(modelInstance) {
            console.log('UserResetPassword:onSubmit  email=' + modelInstance.email)
            this.submitting = true
            return this.$api.resetPassword(this, modelInstance.email, modelInstance.password)
                .then(response => {
                        this.state = this.SUBMITTED
                    },
                    response => {
                        this.state = this.SUBMIT_ERROR
                        this.$api.log(this, 'user', null, 'reset/confirm',
                            `User password reset confirmation failed: ${modelInstance.email}`)
                    })
                .catch(error => {
                    console.log('UserResetPassword:onSubmit  error=' + error)
                    this.state = this.SUBMIT_ERROR
                })
                .finally(() => this.submitting = true)
        }
    },
    mounted() {
        if (this.confirm) {
            this.state = this.CONFIRMING
            return this.$user.confirmPasswordReset(this, this.email, this.confirm)
                .then(response => {
                        this.state = this.CONFIRMED
                    },
                    response => {
                        this.state = this.CONFIRM_ERROR
                    })
                .catch(error => {
                    console.log('UserResetPassword:onSubmit  error=' + error)
                    this.state = this.CONFIRM_ERROR
                })
        }
    }
})

</script>
